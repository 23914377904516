import { StreamsResource } from '@obr-core/resources/StreamsResource'

/**
 * Streams Service
 * Singleton
 */
export class StreamsService {
    private static instance: StreamsService
    private streamResources: StreamsResource

    private constructor() {
        this.streamResources = new StreamsResource()
    }

    /**
     * Return class instance
     */
    public static getInstance(): StreamsService {
        if (StreamsService.instance === undefined) {
            StreamsService.instance = new StreamsService()
        }
        return StreamsService.instance
    }

    /**
     * Get Today's streams list
     *
     * e.g. open streams list
     */
    public getAllStreams() {
        return this.streamResources.getAllStreams()
    }

    /**
     * Get stream resource
     *
     * e.g. play specific stream channel
     */
    public getStreamResource(payload: OBR.Streams.StreamResourceDto) {
        return this.streamResources.getStreamResource(payload)
    }

    /**
     * Close streams or stop media stream
     */
    public closeStreams(requestID: number) {
        return this.streamResources.closeStreams(requestID)
    }

    /**
     * Get stream channel upcoming races
     *
     * e.g. displayed in stream list dropdown of races
     */
    public getStreamRaces(channelID: string) {
        return this.streamResources.getStreamRaces(channelID)
    }
}
