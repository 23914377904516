import { AxiosInstance } from 'axios'
import { userStoreService } from '@obr-core/services/store'
import { device } from '@obr-core/helpers/device.helpers'

export class HttpService {
    /**
     *
     * @param axios Axios instance for making http calls
     * @param userStore userStore for reading the user related data
     */
    constructor(
        private http: AxiosInstance,
        private user: typeof userStoreService
    ) {}

    public setAuthorizationHeader(): void {
        this.http.interceptors.request.use((config) => {
            if (config.url?.includes('/rest/')) {
                const token = this.user.getToken()
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`
                }

                // Set the x-rb-distribution header based on device type (Mobile or Web)
                config.headers['x-rb-distribution'] = device().isMobile
                    ? 'Mobile'
                    : 'Web'
            }
            return config
        })
    }

    public setLanguageHeader(): void {
        this.http.interceptors.request.use((config) => {
            if (config.url?.includes('/rest/')) {
                config.headers['x-rb-language'] = this.user.language()
            }
            return config
        })
    }
}
