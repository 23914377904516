import { UserResource } from '@obr-core/resources/UserResource'
import { get, set } from '@obr-core/lib/user-settings.manager'
import { UserSettings } from '@obr-core/config/user'
import { SettingsService } from '@obr-core/services/api'

/**
 * User Service
 * Singleton
 */
export class UserService {
    private static instance: UserService
    private userResource: UserResource

    private constructor() {
        this.userResource = new UserResource()
    }

    /**
     * Return class instance
     */
    public static getInstance(): UserService {
        if (UserService.instance === undefined) {
            UserService.instance = new UserService()
        }
        return UserService.instance
    }

    /**
     * Returns value for given setting name
     * @param settingName
     */
    public getSetting(settingName: UserSettings): any {
        return get(settingName)
    }

    /**
     * Returns user details
     */
    public getMe(): any {
        return this.userResource.getMe()
    }

    /**
     * Update user setting
     * @param settingName
     * @param settingValue
     */
    public storeSetting(settingName: UserSettings, settingValue: any) {
        set(settingName, settingValue)
    }

    /**
     * Store settings in the API and if successful to local storage
     * @param settings
     */
    public async updateSettings(settings: OBR.Settings.Payload) {
        const settingsService = SettingsService.getInstance()
        try {
            await settingsService.saveSettings(settings)
            this.updateSettingsLocalStorage(settings)
        } catch (e) {
            // no-op
        }
    }

    /**
     * Store settings to local storage
     */
    public updateSettingsLocalStorage(settings: OBR.Settings.Payload) {
        for (const setting in settings) {
            if (isNaN(Number(setting))) {
                const settingName = setting as keyof OBR.Settings.Payload
                if (settingName in settings) {
                    this.storeSetting(
                        settingName as UserSettings,
                        settings[settingName]
                    )
                }
            }
        }
    }

    /**
     * start mobile verification
     */
    public verificationMobile(
        countryCode: number,
        number: string,
        country: OBR.Settings.Country
    ) {
        return this.userResource.verificationMobile(
            countryCode,
            number,
            country
        )
    }

    /**
     * complete mobile verification
     */
    public confirmVerificationMobile(code: string) {
        return this.userResource.confirmVerificationMobile(code)
    }

    /**
     * user balance information
     */
    public balance() {
        return this.userResource.balance()
    }

    /**
     * Switch the design by calling the switchFeature method
     * @param category - The category of the switch
     * @param action - The action for the switch
     */
    public async switchFeatureService() {
        const data = { category: 'old-UI', action: 'switch' }

        try {
            return this.userResource.switchFeature(data)
        } catch (error) {
            throw error
        }
    }
}
