import {
    // USER_DEFAULT_LIMITED_STATUS,
    USER_DEFAULT_MEDIA_COUNTRY,
    UserSettings,
} from '@obr-core/config/user'
import { BuildClient, ENV_CLIENT } from '@obr-core/config/env'
import { I18nService } from '@obr-core/services/I18nService'
import { appStoreService } from '@obr-core/services/store'
import { userCookieService } from '@obr-core/services/UserCookieService'
import { UserService } from '@obr-core/services/api'
import {
    Currency,
    SETTINGS_DEFAULT_BETSLIP_CONFIRMATION,
    SETTINGS_DEFAULT_OPEN_BETSLIP,
    SETTINGS_DEFAULT_CURRENCY,
    SETTINGS_DEFAULT_HIDE_BALANCE,
    SETTINGS_DEFAULT_HIDE_DOGS,
    SETTINGS_DEFAULT_PRESET_STAKES_SINGLE,
    SETTINGS_DEFAULT_PRESET_STAKES_EXOTIC,
    SETTINGS_DEFAULT_PRESET_STAKES_MULTIPLE,
    SETTINGS_DEFAULT_PRESET_STAKES_US,
} from '@obr-core/config/settings'

import { AcceptBetsChanges } from '@obr-ui/components/Settings/config'
import {
    getDefaultUnitSystem,
    getDefaultOddsFormat,
    validatePreferredStakes,
    validateCountryOrder,
    initTimezone,
} from '@obr-core/helpers/settings.helpers'
import { SSRService } from '@obr-core/services/SSRService'

export function initialState(): OBR.Store.UserState {
    // Get session cookie if any
    const session = userCookieService.getSessionId()
    const userSSR = SSRService.getInstance().getUser()
    const bootstrapSSR = SSRService.getInstance().getBootstrap()

    // Other than standalone we do not need to require cookie consent
    // TODO: If standalone check the actual consent cookie already
    const cookieConsent = ENV_CLIENT === BuildClient.STANDALONE ? null : true
    const i18nService = I18nService.getInstance()
    const userService = UserService.getInstance()

    let isLoggedIn = false
    let timezone = initTimezone(
        userService.getSetting(UserSettings.TIMEZONE),
        i18nService.getTimezone()
    )
    let currency = bootstrapSSR?.currency || SETTINGS_DEFAULT_CURRENCY

    if (userSSR) {
        isLoggedIn = userSSR.logged_in
        timezone = userSSR.settings?.timezone || timezone
        currency = userSSR.settings?.currency || currency
    }

    return {
        account_verified: false,
        address: {
            address: '',
            address2: '',
            city: '',
            country: '',
            place_of_birth: '',
            postcode: '',
            tax_number: '',
        },
        balance: 0,
        bets_open: 0,
        betslip_confirmation:
            userService.getSetting(UserSettings.BETSLIP_CONFIRMATION) ??
            SETTINGS_DEFAULT_BETSLIP_CONFIRMATION,
        bonus_balance: 0,
        countries_order: validateCountryOrder(
            userService.getSetting(UserSettings.COUNTRIES_ORDER)
        ),
        currency,
        currency_settings: {
            advent_calendar_consolation: 0,
            advent_calendar_grand_lottery_10x: 0,
            advent_calendar_grand_lottery_1x: 0,
            advent_calendar_grand_lottery_2x: 0,
            advent_calendar_grand_lottery_5x: 0,
            btt_threshold: 0,
            fee_bulk_withdrawals: 0,
            fee_withdrawal_below_minimum: 0,
            fee_withdrawal_non_sepa: 0,
            friendship_bonus_amount: 0,
            friendship_bonus_min_deposit: 0,
            friendship_bonus_min_turnover: 0,
            min_stake_acc: 0,
            max_stake_bok: 0,
            max_stake_fxd: 0,
            max_withdrawal: 0,
            min_feeless_withdrawal: 0,
            min_stake_bok: 0,
            min_stake_boktrise: 0,
            min_stake_fxd: 0,
            pick_6_bonus_4: 0,
            pick_6_bonus_5: 0,
            pick_6_min_turnover: 0,
            sms_fee: 0,
            streams_access_min_turnover_14d: 0,
            threshold_kyc: 0,
            welcome_bonus_max_deposit: 0,
            welcome_bonus_min_deposit: 0,
            welcome_bonus_min_first_bet: 0,
        },
        date_of_birth: '',
        email: {
            email_address: '',
            pending: null,
            verified: false,
        },
        first_name: '',
        free_bets: 0,
        hide_dogs:
            userService.getSetting(UserSettings.HIDE_DOGS) ??
            SETTINGS_DEFAULT_HIDE_DOGS,
        hide_balance:
            userService.getSetting(UserSettings.HIDE_BALANCE) ??
            SETTINGS_DEFAULT_HIDE_BALANCE,
        id_account: session ? session : '',
        id_customer: '',
        last_name: '',
        license_jurisdiction: null,
        // license_jurisdiction: getLicenseJurisdiction(),
        limited_access: {
            access_date: false,
            access_status: null,
        },
        loading: !!isLoggedIn,
        locale: i18nService.getLocale(),
        logged_in: !!isLoggedIn,
        logged_in_node: false,
        registration_method: null,
        media_country: USER_DEFAULT_MEDIA_COUNTRY,
        mobile: '',
        next_race: null,
        no_bonus: true,
        odds_format:
            userService.getSetting(UserSettings.ODDS_FORMAT) ??
            getDefaultOddsFormat(appStoreService.getCountry()),
        pending_bonuses: 0,
        preferred_stakes_exotic: validatePreferredStakes(
            userService.getSetting(UserSettings.PREFERRED_STAKES_EXOTIC),
            SETTINGS_DEFAULT_PRESET_STAKES_EXOTIC[currency]
        ),
        preferred_stakes_multiple: validatePreferredStakes(
            userService.getSetting(UserSettings.PREFERRED_STAKES_MULTIPLE),
            SETTINGS_DEFAULT_PRESET_STAKES_MULTIPLE[currency]
        ),
        preferred_stakes_single: validatePreferredStakes(
            userService.getSetting(UserSettings.PREFERRED_STAKES_SINGLE),
            SETTINGS_DEFAULT_PRESET_STAKES_SINGLE[currency]
        ),
        preferred_stakes_usd: validatePreferredStakes(
            userService.getSetting(UserSettings.PREFERRED_STAKES_US),
            SETTINGS_DEFAULT_PRESET_STAKES_US[Currency.USD]
        ),
        promotion_consent: {
            brand: null,
            mailings: null,
            phone: null,
            sms: null,
            group: null,
            cookie: cookieConsent,
            thirdparty: null,
        },
        tax: {
            taxable: false,
            deductions: [],
        },
        timezone,

        unit_system:
            userService.getSetting(UserSettings.UNIT_SYSTEM) ??
            getDefaultUnitSystem(appStoreService.getCountry()),
        username: '',
        withdrawable: 0,
        best_odds_guaranteed: false,
        accept_odds:
            userService.getSetting(UserSettings.ACCEPT_ODDS) ??
            AcceptBetsChanges.ALL,
        open_betslip:
            userService.getSetting(UserSettings.OPEN_BETSLIP) ??
            SETTINGS_DEFAULT_OPEN_BETSLIP,
        token: null,
        ignore_bookie_preset: false,
    }
}
