import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "600",
  height: "180",
  fill: "none",
  viewBox: "0 0 865 218"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#037E21",
      d: "M81.598 217.095c-17.823 0-33.617-6.927-45.107-18.92v15.794H0V.169h38.18v79.91C49.419 69.18 64.62 62.846 81.599 62.846c38.773 0 70.368 33.366 70.368 77.294 0 43.67-31.595 76.954-70.368 76.954M76.102 97.902c-20.95 0-37.928 18.92-37.928 42.491 0 22.979 16.979 41.647 37.928 41.647 20.95 0 37.929-18.075 37.929-41.647 0-24.157-16.898-42.491-37.929-42.491M206.022 154.751c4.896 18.076 19.26 29.313 37.084 29.313 12.667 0 25.001-5.489 31.342-17.23h40.544c-8.616 30.157-34.803 50.596-71.546 50.596-41.647 0-75.857-34.462-75.857-77.294s34.21-77.887 75.857-77.887c43.67 0 79.909 33.876 74.42 92.496H206.022zm74.167-30.995c-4.563-17.231-19.008-28.128-37.083-28.128-17.231 0-31.003 10.897-36.492 28.128zM415.768 216.17c-43.417 4.563-66.396-21.542-66.396-62.086v-52.033h-17.824V68.515h17.824V30.838H387.3v37.677h26.691v33.617H387.3v52.292c0 20.698 9.46 28.469 28.468 27.284zM501.087 217.437c-37.677 0-65.553-19.853-70.116-51.7h38.521c5.156 12.668 20.357 17.231 31.595 17.231 15.208 0 26.691-4.563 26.691-13.179 0-8.363-5.156-11.741-31.002-15.793-26.439-4.053-61.752-11.238-61.752-44.263 0-23.313 20.445-47.388 63.189-47.388 33.876 0 59.218 18.416 64.115 46.292h-39.365c-2.282-6.927-10.645-12.335-24.75-12.335-17.483 0-24.75 6.334-24.75 11.742 0 7.771 10.897 10.305 29.313 13.179 36.743 6 63.189 14.105 63.189 46.292 0 29.224-26.95 49.922-64.878 49.922"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#037E21",
      "fill-opacity": ".87",
      d: "M666.653 217.348h-40.292V65.716h-32.943V38.855c18.92-.34 38.773-11.912 40.885-38.855h32.35zM865 109.052c0 79.657-40.632 108.044-78.472 108.044-38.181 0-78.731-28.38-78.731-108.044S748.429 1.008 786.528 1.008C824.374.926 865 29.306 865 109.052m-39.958 0c0-21.372-3.971-71.124-38.433-71.124-34.802 0-38.773 49.752-38.773 71.124 0 21.031 3.971 70.79 38.773 70.79 34.462 0 38.433-49.752 38.433-70.79"
    }, null, -1)
  ])))
}
export default { render: render }