<script lang="ts">
export default {
    name: 'ObrLogo',
}
</script>

<script lang="ts" setup>
import { FunctionalComponent, computed } from 'vue'

import arcticbet from './assets/arcticbet/logo.svg?component'
import b10 from './assets/b10/logo.svg?component'
import bets10 from './assets/bets10/logo.svg?component'
import betsafe from './assets/betsafe/logo.svg?component'
import betsmith from './assets/betsmith/logo.svg?component'
import betsolid from './assets/betsolid/logo.svg?component'
import betsson from './assets/betsson/logo.svg?component'
import mobilbahis from './assets/mobilbahis/logo.svg?component'
import nordicbet from './assets/nordicbet/logo.svg?component'
import oranjecasino from './assets/oranjecasino/logo.svg?component'
import racebets from './assets/racebets/logo.svg?component'
import europebet from './assets/europebet/logo.svg?component'

const logos: OBR.Common.Object<FunctionalComponent> = {
    arcticbet,
    b10,
    bets10,
    betsafe,
    betsmith,
    betsolid,
    betsson,
    mobilbahis,
    nordicbet,
    oranjecasino,
    racebets,
    europebet,
}

const brand = computed(
    () => document.obrConfig?.brand || import.meta.env.VUE_APP_BRAND
)
const logo = computed(() => logos[brand.value])
</script>

<template>
    <component :is="logo" class="obr-logo-size"></component>
</template>
