/**
 * Project specific Sentry DSN
 * https://sentry.io/racebets
 */
export const LOGGER_SENTRY_DSN: string = import.meta.env.VUE_APP_SENTRY_DSN

export const LOGGER_SENTRY_IS_ENABLED: boolean =
    import.meta.env.VUE_APP_RB_ENV !== 'local-develop'

/**
 * Release version
 */
export const LOGGER_SENTRY_RELEASE = import.meta.env.VUE_APP_SENTRY_RELEASE

/**
 * Ignore errors with error messages matching a regex pattern or an exact string
 */
export const LOGGER_IGNORE_ERRORS: Array<RegExp | string> = [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    'ChunkLoadError',
    'Gateway Timeout',
    'Blocked a frame with origin',
    'ibFindAllVideos',
    'Error: Request failed with status code 401',
    'Request failed with status code 422',
    'ERR_CANCELED',
    'Request aborted',
]

/**
 * Ignore errors from whole URLs matching a regex pattern or an exact string
 */
export const LOGGER_IGNORE_URLS: Array<RegExp | string> = [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    /scoutgg\.net/gim,
]

/**
 * Tag brand
 */
export const LOGGER_TAG_BRAND: string = 'brand'

/**
 * Sentry breadcrumbs websockets category
 */
export const LOGGER_SENTRY_BREADCRUMB_WEBSOCKETS: string = 'websockets'
