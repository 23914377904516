import { POST_MESSAGE_ID_POPUP } from '@obr-core/config/post-messages'
import {
    getBasePath,
    isIframe,
    isTouchScreen,
} from '@obr-core/helpers/app.helpers'
import { postMessageService } from '@obr-core/services/PostMessageService'

function openNewWindow(
    windowLink: string,
    windowName: string,
    windowConfig: string
) {
    const openedWindow = window.open(
        windowLink,
        windowName,
        isTouchScreen() ? '' : windowConfig
    )

    if (openedWindow) {
        ;(openedWindow as any).originalBasePath = getBasePath()
    }
}

interface OpenOptions {
    url: string
    name: string
    height: number
    width: number
    windowConfig: string
}

export function open(options: OpenOptions) {
    if (isIframe()) {
        postMessageService.send(POST_MESSAGE_ID_POPUP, {
            width: options.width,
            height: options.height,
            scroll: 'yes',
            url: options.url,
        })
    } else {
        try {
            const existingWindow = Array.from(window.parent.frames).find(
                (frame) => frame.name === options.name
            )

            if (existingWindow) {
                existingWindow.focus()
            } else {
                openNewWindow(options.url, options.name, options.windowConfig)
            }
        } catch {
            openNewWindow(options.url, options.name, options.windowConfig)
        }
    }
}
