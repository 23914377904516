import { BuildClient, ENV_CLIENT, ENV_RB, RBEnv } from '@obr-core/config/env'
import {
    POST_MESSAGE_TARGET_ORIGIN,
    POST_MESSAGE_FILTER_LIST,
} from '@obr-core/config/post-messages'
import { postMessageLogger } from '@obr-core/helpers/post-messages.helpers'

/**
 * initialization (set `message` eventListener on window)
 */
export function init(
    publish: (
        event: MessageEvent,
        messenger: OBR.PostMessages.Messenger
    ) => void,
    messenger: OBR.PostMessages.Messenger
) {
    window.addEventListener(
        'message',
        (event: MessageEvent) => {
            if (!event.data?.messageId) {
                return
            }

            if (ENV_RB !== RBEnv.PRODUCTION) {
                postMessageLogger(event)
            }

            if (
                ENV_RB !== RBEnv.PRODUCTION ||
                POST_MESSAGE_FILTER_LIST.some((item) => {
                    if (typeof item === 'object') {
                        return item.test(event.origin)
                    } else {
                        return event.origin.includes(item)
                    }
                })
            ) {
                publish(event, messenger)
            }
        },
        false
    )
}

/**
 * post message function
 * @param messageId
 * @param payload
 */
export function postMessage(
    messageId: string,
    payload: OBR.Common.Object<any>
) {
    if (window.self === window.top || ENV_CLIENT === BuildClient.STANDALONE) {
        return
    }
    window.parent.postMessage(
        { messageId, payload },
        POST_MESSAGE_TARGET_ORIGIN
    )
}
