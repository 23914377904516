import { isTimestampInPast } from '@obr-core/helpers/date.helpers'
import {
    getDisplayValueForRaceNumberShort,
    getDisplayValueForTime,
} from '@obr-core/helpers/display-value.helpers'
import { getRaceCardLinkExtended } from '@obr-ui/helpers/link-extended.helpers'

/**
 * Create channels for streams list component
 *
 * e.g. to create channels use channels from API
 */
export function createStreamsListChannels(
    channels: OBR.UI.Components.Streams.ChannelWithRaces[]
): OBR.UI.Components.Streams.Channel[] {
    const results: OBR.UI.Components.Streams.Channel[] = []

    // Sort channels by starting time
    channels = channels.sort((a, b) => a.start_time - b.start_time)

    for (let i = 0; i < channels.length; i++) {
        const item = channels[i]

        const channel: OBR.UI.Components.Streams.Channel = {
            id: item.id_channel,
            title: item.name,
            subtitle: item.tracks,
            time: getDisplayValueForTime(item.start_time),
            disabled: !isTimestampInPast(item.start_time),
            races: item.races.map(createStreamListItemRace),
            raw: item,
        }

        results.push(channel)
    }

    return results
}

/**
 * Create race for stream list item component
 *
 * e.g. to create races use stream races from API
 */
export function createStreamListItemRace(
    race: OBR.Streams.StreamRace
): OBR.UI.Components.Streams.ChannelRace {
    return {
        country: race.country,
        numRunners: race.num_starters,
        title: `${getDisplayValueForTime(race.post_time)} ${
            race.title
        } · ${getDisplayValueForRaceNumberShort(race.race_number)}`,
        raceType: race.race_type,
        link: getRaceCardLinkExtended(
            race.id_race,
            race.country,
            race.title,
            race.race_type,
            race.race_number
        ),
    }
}

/**
 * Create channels with races array
 *
 * i.e. add races to channel object
 */
export function createChannelsWithRaces(
    channels: OBR.Streams.Channel[]
): OBR.UI.Components.Streams.ChannelWithRaces[] {
    return channels.map((channel) => ({ ...channel, races: [] }))
}

/**
 * Update channel races by ID
 *
 * i.e. update channel races
 */
export function updateChannelRacesByIdChannel(
    idChannel: string,
    channels: OBR.UI.Components.Streams.ChannelWithRaces[],
    races: OBR.Streams.StreamRace[]
) {
    return channels.map((channel) => {
        if (channel.id_channel === idChannel) {
            channel.races = races
        }

        return channel
    })
}

/**
 * Handle audio listeners when audio is playing
 */
export function handleAudioListeners(
    player: OBR.UI.Components.Streams.ObrVideoJSPlayer
) {
    player.on('play', () => {
        window.dataLayer.push({
            event: 'audio',
            Action: 'Play',
        })
    })

    player.on('pause', () => {
        window.dataLayer.push({
            event: 'audio',
            Action: 'Pause',
        })
    })

    player.on('ended', () => {
        window.dataLayer.push({
            event: 'audio',
            Action: 'Ended',
        })
    })

    player.on('seeking', () => {
        window.dataLayer.push({
            event: 'audio',
            Action: 'Seeking',
        })
    })

    player.on('tracking:first-quarter', () => {
        window.dataLayer.push({
            event: 'audio',
            Action: 'Progress 25%',
        })
    })

    player.on('tracking:second-quarter', () => {
        window.dataLayer.push({
            event: 'audio',
            Action: 'Progress 50%',
        })
    })

    player.on('tracking:third-quarter', () => {
        window.dataLayer.push({
            event: 'audio',
            Action: 'Progress 75%',
        })
    })

    player.on('tracking:fourth-quarter', () => {
        window.dataLayer.push({
            event: 'audio',
            Action: 'Progress 100%',
        })
    })
}
