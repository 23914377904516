import { HTTP_REST_V2 } from '@obr-core/config/http'
import { http } from '@obr-core/lib/http'
import {
    Currency,
    SETTINGS_DEFAULT_PRESET_STAKES_SINGLE,
    SETTINGS_DEFAULT_PRESET_STAKES_EXOTIC,
    SETTINGS_DEFAULT_PRESET_STAKES_MULTIPLE,
    SETTINGS_DEFAULT_PRESET_STAKES_US,
    SETTINGS_DEFAULT_CURRENCY,
} from '@obr-core/config/settings'
import {
    validatePreferredStakes,
    validateCountryOrder,
} from '@obr-core/helpers/settings.helpers'

export class UserResource {
    private readonly apiPath: string = `${HTTP_REST_V2}/users`

    /**
     * API request me
     */
    public async getMe() {
        const url = `${this.apiPath}/me`
        const response = await http.get<OBR.User.MeResponse>(url)

        return this.parseMeResponse(response.data)
    }

    /**
     * start mobile verification
     */
    public verificationMobile(
        countryCode: number,
        number: string,
        country: OBR.Settings.Country
    ) {
        return http
            .post(`${HTTP_REST_V2}/users/me/mobile_verification`, {
                code: countryCode,
                country,
                mobile_number: number,
            })
            .catch((error) => {
                // v2 call
                /* error.message =
                    error?.response?.data?.error?.message?.toLowerCase() ||
                    error.message */
                // v1 call
                error.message = error?.response?.data?.data?.code
                    ? `error_${error.response.data.data.code}`
                    : error.message
                throw error
            })
    }

    /**
     * complete mobile verification with OTP generated
     */
    public confirmVerificationMobile(code: string) {
        return http
            .post(`${HTTP_REST_V2}/users/me/verify_mobile_code`, {
                code,
            })
            .catch((error) => {
                // v2 call
                /* error.message =
                error?.response?.data?.error?.message?.toLowerCase() ||
                error.message */
                // v1 call
                error.message = error?.response?.data?.data?.code
                    ? `error_${error.response.data.data.code}`
                    : error.message
                throw error
            })
    }

    public balance(): Promise<OBR.User.Balance> {
        return http
            .get(`${this.apiPath}/me/balance`)
            .then((res) => this.parseBalanceResponse(res.data))
    }

    /**
     * Switch the feature using /rest/v2/switch endpoint
     * @param data - The data to be sent to the API
     */
    public async switchFeature(data: { category: string; action: string }) {
        return http
            .post(`${HTTP_REST_V2}/switch`, data)
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                if (error?.response?.data) {
                    if (error.response.data.error?.message) {
                        error.message =
                            error.response.data.error.message.toLowerCase()
                    } else if (error.response.data.data?.code) {
                        error.message = `error_${error.response.data.data.code}`
                    }
                }
                throw error
            })
    }

    /**
     * Parse response to match app state tree
     * @param response API response
     */
    private parseBalanceResponse(res: OBR.User.Balance): OBR.User.Balance {
        res.freebets?.map((freebet) => {
            freebet.id_freebet = `${freebet.id_freebet}`
            return freebet
        })
        return res
    }

    /**
     * Parses the response of the 'Me' API endpoint.
     *
     */
    private parseMeResponse(
        response: OBR.User.MeResponse
    ): OBR.User.MeResponse {
        // validate currency
        response.currency = Object.values(Currency).includes(response.currency)
            ? response.currency
            : SETTINGS_DEFAULT_CURRENCY

        // validate preferred stakes
        response.settings.preferred_stakes_exotic = validatePreferredStakes(
            response.settings.preferred_stakes_exotic,
            SETTINGS_DEFAULT_PRESET_STAKES_EXOTIC[response.currency]
        )
        response.settings.preferred_stakes_multiple = validatePreferredStakes(
            response.settings.preferred_stakes_multiple,
            SETTINGS_DEFAULT_PRESET_STAKES_MULTIPLE[response.currency]
        )
        response.settings.preferred_stakes_single = validatePreferredStakes(
            response.settings.preferred_stakes_single,
            SETTINGS_DEFAULT_PRESET_STAKES_SINGLE[response.currency]
        )
        response.settings.preferred_stakes_usd = validatePreferredStakes(
            response.settings.preferred_stakes_usd,
            SETTINGS_DEFAULT_PRESET_STAKES_US[Currency.USD]
        )
        // validate countries order
        response.settings.countries_order = validateCountryOrder(
            response.settings.countries_order
        )
        return response
    }
}
