/**
 * Generate random number for repeated components
 */
export const generateId = () => {
    const timestamp = new Date().getTime().toString()
    const randomStr = Math.random().toString(36).substring(2, 15) // Generate a random string of length 13
    return timestamp + randomStr
}

/**
 * Generic function sort list by number
 * @param list list
 * @param property list item property
 * @param order list order
 */
export function sortByNumber<T>(
    list: T[],
    property: keyof T,
    order: OBR.UI.Helpers.Order = 'asc',
    withScratched: boolean = false
) {
    // if property is "scratched" the runner will be moved at the bottom of the list

    if (order === 'asc') {
        return list.sort((a: any, b: any) => {
            if (withScratched) {
                const aHasProperty = Boolean(a['scratched'])
                const bHasProperty = Boolean(b['scratched'])

                if (aHasProperty && !bHasProperty) {
                    return 1 // `a` comes after `b`
                }
                if (!aHasProperty && bHasProperty) {
                    return -1 // `a` comes before `b`
                }
            }

            if (!a[property]) {
                return 1
            }
            if (!b[property]) {
                return -1
            }
            return a[property] - b[property]
        })
    }

    return list.sort((a: any, b: any) => {
        if (withScratched) {
            const aHasProperty = Boolean(a['scratched'])
            const bHasProperty = Boolean(b['scratched'])

            if (aHasProperty && !bHasProperty) {
                return 1 // `a` comes after `b`
            }
            if (!aHasProperty && bHasProperty) {
                return -1 // `a` comes before `b`
            }
        }
        if (!a[property]) {
            return 1
        }
        if (!b[property]) {
            return -1
        }
        return b[property] - a[property]
    })
}

/**
 * Generic function sort list by string
 * @param list list
 * @param property list item property
 * @param order list order
 */
export function sortByString<T>(
    list: T[],
    property: keyof T,
    order: OBR.UI.Helpers.Order = 'asc'
) {
    if (order === 'asc') {
        return list.sort((a: any, b: any) =>
            a[property].localeCompare(b[property])
        )
    }

    return list.sort((a: any, b: any) => b[property].localeCompare(a[property]))
}
