import { createApp } from 'vue'

import { i18n } from '@obr-core/i18n/i18n'
import { store } from '@obr-core/store/store'
import { registerGlobalComponents } from '@obr-ui/helpers/components.helpers'
import { initialiseDirectives } from '@obr-ui/directives/directives'
import { ErrorService } from '@obr-core/services/ErrorService'
import { APP_MOUNT } from '@obr-core/config/app'
import { HttpService } from '@obr-core/services/HttpService'
import { http } from '@obr-core/lib/http'
import { userStoreService } from '@obr-core/services/store'
import { loadDictionaryManifest } from '@obr-core/helpers/load-dictionary-manifest'
import {
    getCachedMessages,
    getPreferredLocale,
    prepareCaches,
} from '@obr-core/helpers/i18n.helpers'
import { I18nService } from '@obr-core/services/I18nService'
import { errorLogger } from '@obr-core/services/ErrorLogger'
import { router } from '@/router/router'

import '@obr-ui-styles/styles.scss'

const httpService = new HttpService(http, userStoreService)

httpService.setLanguageHeader()

import App from '@obr-brand/App.vue'

loadDictionaryManifest()
    .then((hash) => {
        const locale = getPreferredLocale()
        prepareCaches(locale, hash)

        const messages = getCachedMessages(locale)

        const i18nService = I18nService.getInstance()
        i18nService.setCachedModules(Object.keys(messages) as OBR.I18n.Module[])

        if (Object.keys(messages).length > 0) {
            i18n.global.setLocaleMessage(locale, messages)
        }
    })
    .catch((e) => errorLogger.createException(e))
    .finally(() => {
        let app = createApp(App)

        app.use(i18n)
        app.use(store)
        app.use(router)
        app = initialiseDirectives(app)
        app = registerGlobalComponents(app)

        // Initialize error service (Sentry)
        ErrorService.getInstance().initialize({ app, router })

        app.mount(APP_MOUNT)
    })
