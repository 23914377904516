let breakpoints: {
    xxs: number
    xs: number
    sm: number
    md: number
    lg: number
    xl: number
    '2xl': number
    '3xl': number
} | null = null

/**
 * Get number value from CSS variable
 * @param computedStyle
 * @param variable
 */
function getNumberValueFromCSSVariable(
    computedStyle: CSSStyleDeclaration,
    variable: string
) {
    const stringValue = computedStyle
        .getPropertyValue(variable)
        .trim()
        .replace('px', '')

    return Number(stringValue)
}

/**
 * Get CSS Breakpoints
 */
export function getBreakpoints() {
    if (breakpoints === null || breakpoints?.xxs === 0) {
        const WC_TAG = import.meta.env.VUE_APP_WEBCOMPONENT_TAG_NAME
        let styleNode = document.documentElement.querySelector(WC_TAG)

        if (!styleNode) {
            styleNode = document.documentElement
        }

        const computedStyle = getComputedStyle(styleNode)

        breakpoints = {
            xxs: getNumberValueFromCSSVariable(
                computedStyle,
                '--breakpoint-xxs'
            ),
            xs: getNumberValueFromCSSVariable(computedStyle, '--breakpoint-xs'),
            sm: getNumberValueFromCSSVariable(computedStyle, '--breakpoint-sm'),
            md: getNumberValueFromCSSVariable(computedStyle, '--breakpoint-md'),
            lg: getNumberValueFromCSSVariable(computedStyle, '--breakpoint-lg'),
            xl: getNumberValueFromCSSVariable(computedStyle, '--breakpoint-xl'),
            '2xl': getNumberValueFromCSSVariable(
                computedStyle,
                '--breakpoint-2xl'
            ),
            '3xl': getNumberValueFromCSSVariable(
                computedStyle,
                '--breakpoint-3xl'
            ),
        }
    }

    return breakpoints
}

// these functions use firstMobile Check
export function isTabletPortrait(): boolean {
    const breakpoints = getBreakpoints()
    return window.innerWidth >= breakpoints.sm
}

export function isTabletLandscape(): boolean {
    const breakpoints = getBreakpoints()
    return window.innerWidth >= breakpoints.md
}

export function isLaptop(): boolean {
    const breakpoints = getBreakpoints()
    return window.innerWidth >= breakpoints.xl
}

export function isDesktop(): boolean {
    const breakpoints = getBreakpoints()
    return window.innerWidth >= breakpoints['2xl']
}
