import {
    BuildClient,
    BuildTarget,
    ENV_CLIENT,
    ENV_TARGET,
} from '@obr-core/config/env'

/**
 * detect if our application is in Iframe
 */
export function isIframe() {
    if (document.obrConfig && document.obrConfig.client) {
        return document.obrConfig.client === BuildClient.IFRAME
    }
    return ENV_CLIENT === BuildClient.IFRAME
}

export function isWebComponent() {
    if (document.obrConfig && document.obrConfig.target) {
        return document.obrConfig.target === BuildTarget.WC
    }
    return ENV_TARGET === BuildTarget.WC
}

export function isStandalone(): boolean {
    if (document.obrConfig && document.obrConfig.client) {
        return document.obrConfig.client === BuildClient.STANDALONE
    }
    return ENV_CLIENT === BuildClient.STANDALONE
}

/**
 * detect if parent window is the same as currant window
 */
export function isParentSameWin() {
    return window.self === window.top
}

/**
 * detect if app is open on deveice with touch screen
 */
export function isTouchScreen(): boolean {
    return (
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        (navigator as any).msMaxTouchPoints > 0
    )
}

export function getBasePath(): string {
    const horseRacingTag = document.getElementsByTagName(
        import.meta.env.VUE_APP_WEBCOMPONENT_TAG_NAME
    )[0] as any

    if (horseRacingTag) {
        const basePath = (horseRacingTag.getAttribute('base-path') ||
            '') as string
        return basePath.replace(/^(\/{1,})/, '/').replace(/(\/{1,})$/, '/')
    }

    return ''
}

/**
 * Build Webcomponent path for Vue router that should always start
 * with a forward slash and should never end with a forward slash.
 *
 * Ex.
 * en/horse-racing/ -> /en/horse-racing
 *
 * @param path string
 * @returns string
 */
export function buildWebcomponentBasePath(path: string): string {
    return path
        .split('/')
        .filter(Boolean)
        .join('/')
        .replace(/^(?!=\/)/, '/')
        .replace(/\/$/, '')
}

export function getLanguage(): string {
    const horseRacingTag = document.getElementsByTagName(
        import.meta.env.VUE_APP_WEBCOMPONENT_TAG_NAME
    )[0] as any

    if (horseRacingTag) {
        const language = horseRacingTag.getAttribute('language')

        if (language) {
            return language
        }

        return ''
    }

    return ''
}
