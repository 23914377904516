import {
    I18N_AVAILABLE_LANGUAGES,
    I18N_DEFAULT_LOCALE,
    I18N_INIT_MODULES_CUSTOMER,
    I18N_INIT_MODULES_PROSPECT,
    I18N_SEARCH_PARAM_KEY,
    I18N_STORAGE_KEY_HASHMAP,
    I18N_STORAGE_KEY_LOCALE,
    I18N_STORAGE_KEY_MODULES,
    I18N_WINDOW_KEY,
} from '@obr-core/config/i18n'
import { getLanguage } from '@obr-core/helpers/app.helpers'
import { parseJSON, isObject } from '@obr-core/utils/utils'
import { getItem, setItem } from '@obr-core/lib/storage.manager'

/**
 * Prepare cached hashmap and messages
 * @param locale
 */
export function prepareCaches(
    locale: OBR.I18n.Locale,
    dictionaryHashmap: OBR.I18n.DictionaryHashmap
): void {
    const cachedHashmap = getCachedHashmap(locale)
    const prerenderedMessages = getPrerenderedMessages(locale)
    const cachedMessages = getCachedMessages(locale)
    const hashmap = dictionaryHashmap[mapLocaleToLanguage(locale)]

    // Invalidate messages with old hash
    const messages = Object.keys(cachedMessages).reduce((messages, curr) => {
        const module = curr as OBR.I18n.Module
        if (
            cachedHashmap &&
            cachedHashmap[module] === hashmap[module] &&
            isObject(cachedMessages[module])
        ) {
            messages[module] = cachedMessages[module]
        }
        return messages
    }, {} as OBR.I18n.LocaleMessages<any>)

    // Store validated messages
    storeMessages(locale, {
        ...messages,
        ...prerenderedMessages,
    })

    // Store up to date hashmap
    storeHashmap(locale, hashmap)
}

/**
 * Save messages to local storage
 * @param locale
 * @param messages
 */
export function storeMessages(
    locale: OBR.I18n.Locale,
    messages: OBR.I18n.LocaleMessages<any>
): void {
    setItem(I18N_STORAGE_KEY_MODULES, {
        language: mapLocaleToLanguage(locale),
        messages,
    })
}

/**
 * Save hashmap to local storage
 * @param locale
 * @param hashmap
 */
export function storeHashmap(
    locale: OBR.I18n.Locale,
    hashmap: OBR.I18n.Hashmap
): void {
    setItem(I18N_STORAGE_KEY_HASHMAP, {
        language: mapLocaleToLanguage(locale),
        hashmap,
    })
}

/**
 * Set lang attribute on html element
 * @param locale
 */
export function setLangAttribute(locale: OBR.I18n.Locale): void {
    // As we don't serve localised versions of the same language,
    // we just cut off the country part
    document.documentElement.setAttribute('lang', mapLocaleToLanguage(locale))
}

/**
 * Modules to load on app init based on user's logged in state
 * @param loggedIn
 */
export function getBaseModules(loggedIn: boolean): OBR.I18n.Module[] {
    return loggedIn ? I18N_INIT_MODULES_CUSTOMER : I18N_INIT_MODULES_PROSPECT
}

/**
 * Get modules by all matching route records
 * @param records Vue Router Route records
 * @returns i18n modules
 */
export function getMatchedModules(
    records: OBR.Router.RouteRecord[]
): OBR.I18n.Module[] {
    return records.reduce((modules, record) => {
        if (record.meta.i18n_modules) {
            modules = modules.concat(
                record.meta.i18n_modules as OBR.I18n.Module
            )
        }
        return modules
    }, [] as OBR.I18n.Module[])
}

/**
 * Get preferred locale
 * 1. priority: If iframe or webcomponent, language is controlled by host
 * 2. priority: Detect language based on URL search param
 * 3. priority: Detect language based on the browser's settings
 */
export function getPreferredLocale(): OBR.I18n.Locale {
    const languageProp = getLanguage()
    const searchParams = new URLSearchParams(window.location.search)
    let languageParam =
        languageProp || searchParams.get(I18N_SEARCH_PARAM_KEY) || ''

    const browserLanguage = getBrowserLanguage() || ''

    if (languageParam === 'br') {
        languageParam = 'pt'
    }

    if (isLocaleAvailable(languageParam)) {
        return formatLocale(languageParam)
    } else if (isLocaleAvailable(browserLanguage)) {
        return formatLocale(browserLanguage)
    }

    return I18N_DEFAULT_LOCALE
}

/**
 * Map detected locale string to available languages
 * Example: "de-AT" returns "de" if available
 * Defaults to config
 * @param locale
 */
export function mapLocaleToLanguage(locale: OBR.I18n.Locale): OBR.I18n.Locale {
    const localeFormatted = formatLocale(locale).split('-')[0]
    if (document.obrConfig && document.obrConfig.languages) {
        const availableLanguages = Object.keys(document.obrConfig.languages)
        if (availableLanguages.includes(localeFormatted)) {
            return localeFormatted
        }
    } else {
        if (I18N_AVAILABLE_LANGUAGES.includes(localeFormatted)) {
            return localeFormatted
        }
    }
    return I18N_DEFAULT_LOCALE.split('-')[0]
}

/**
 * Map country to the correct locale
 * When a 2 letter country code is passed this will return the correct locale.
 *
 * Example:
 * `br` from Brazilian facing `betsson.com/br` returns Brazilian Portuguese locale `pt-BR`
 * @param country
 */
export function mapCountryToLocale(
    country: OBR.Settings.Country
): OBR.I18n.Locale {
    switch (country.toLowerCase()) {
        case 'ag':
            return 'es-AR'
        case 'br':
            return 'pt-BR'
        case 'ca':
            return 'en-CA'
        case 'cl':
            return 'es-CL'
        case 'co':
            return 'es-CO'
        case 'da':
            return 'da-DK'
        case 'nl':
            return 'nl-NL'
        case 'pe':
            return 'es-PE'
        case 'mx':
            return 'es-MX'
        default:
            return 'en'
    }
}

/**
 * Pick up server rendered i18n messages
 * @param locale
 */
export function getPrerenderedMessages(
    locale: OBR.I18n.Locale
): OBR.I18n.LocaleMessages<any> {
    const language = mapLocaleToLanguage(locale)
    const dictionary: OBR.I18n.DictionaryCache | undefined = parseJSON(
        (window as any)[I18N_WINDOW_KEY]
    )

    if (
        dictionary &&
        isObject(dictionary) &&
        dictionary.language === language
    ) {
        const messages: OBR.I18n.LocaleMessages<any> = removeDoubleBracket({
            ...dictionary.messages,
        })
        delete (window as any)[I18N_WINDOW_KEY]
        return messages
    }

    return {}
}

/**
 * Get matching language messages from local storage
 * @param locale
 */
export function getCachedMessages(
    locale: OBR.I18n.Locale
): OBR.I18n.LocaleMessages<any> {
    const language = mapLocaleToLanguage(locale)
    const cachedDictionary: OBR.I18n.DictionaryCache = getItem(
        I18N_STORAGE_KEY_MODULES
    )
    if (isObject(cachedDictionary) && cachedDictionary.language === language) {
        return cachedDictionary.messages
    }
    return {}
}

/**
 * Get matching language hashmap from local storage
 * @param locale
 */
export function getCachedHashmap(
    locale: OBR.I18n.Locale
): OBR.I18n.Hashmap | null {
    const language = mapLocaleToLanguage(locale)
    const cachedHashmap: OBR.I18n.DictionaryHashmapCache | null = getItem(
        I18N_STORAGE_KEY_HASHMAP
    )
    if (
        cachedHashmap !== null &&
        isObject(cachedHashmap) &&
        cachedHashmap.language === language &&
        cachedHashmap.hashmap
    ) {
        return cachedHashmap.hashmap
    }

    return null
}

/**
 * Reformat string to locale standard `en-GB` in case it comes from
 * unverified source (URL)
 * @param localeString
 */
export function formatLocale(localeString: OBR.I18n.Locale): OBR.I18n.Locale {
    const locale = localeString.split('-')
    if (locale.length > 1) {
        return `${locale[0].toLowerCase()}-${locale[1].toUpperCase()}`
    }
    return locale[0].toLowerCase()
}

/**
 * Get language from browser navigator
 */
function getBrowserLanguage(): OBR.I18n.Locale | null {
    const nav: Navigator = window.navigator
    const browserLanguagePropertyKeys: string[] = [
        'language',
        'browserLanguage',
        'systemLanguage',
        'userLanguage',
    ]
    let i = 0
    let language: string

    // Support for HTML 5.1 "navigator.languages"
    if (nav.languages.length) {
        for (i = 0; i < nav.languages.length; i++) {
            language = nav.languages[i]
            if (language && language.length) {
                return language
            }
        }
    }

    // Support for other well known properties in browsers
    for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
        language = (nav as any)[browserLanguagePropertyKeys[i]]
        if (language && language.length) {
            return language
        }
    }

    return null
}

/**
 * Get user's preferred language from local storage
 */
function getPreferredLanguage(): OBR.I18n.Locale | null {
    const localStorageLanguage = getItem(I18N_STORAGE_KEY_LOCALE)

    if (localStorageLanguage && isLocaleAvailable(localStorageLanguage)) {
        return localStorageLanguage as OBR.I18n.Locale
    }

    return null
}

/**
 * If language from locale is available in app
 * @param locale
 */
export function isLocaleAvailable(locale: OBR.I18n.Locale): boolean {
    const parsedLocale = locale.toLowerCase().split('-')[0]
    if (document.obrConfig && document.obrConfig.languages) {
        const availableLanguages = Object.keys(document.obrConfig.languages)
        return !!locale && availableLanguages.includes(parsedLocale)
    } else {
        return !!locale && I18N_AVAILABLE_LANGUAGES.includes(parsedLocale)
    }
}

/**
 * replace for all translation {{ ... }} -> { ... }
 */
export function removeDoubleBracket(msg: any) {
    if (msg && typeof msg === 'object') {
        for (const key in msg) {
            if (typeof msg[key] === 'string' && msg[key].includes('{{')) {
                msg[key] = msg[key].replace(/{{/g, '{').replace(/}}/g, '}')
            } else {
                removeDoubleBracket(msg[key])
            }
        }
    }
    return msg
}
