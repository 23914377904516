import { i18n } from '@obr-core/i18n/i18n'
import { formatToLocaleNumberWithCurrency } from '@obr-core/utils/number.format'
import { userStoreService } from '@obr-core/services/store'
import { ModalService } from '@obr-core/services/ModalService'
import { MediaError } from '@obr-core/config/media'
import { ModalSimpleStatus } from '@obr-core/config/modal'

/**
 * Handle all media errors
 *
 * i.e. for  "/streams" endpoints
 */
export function onStreamsUserErrors(error: string) {
    let message: string | null = null
    let status = ModalSimpleStatus.ERROR

    switch (error) {
        case MediaError.INVALID_RESOURCE:
            message = i18n.global.t('generic.msg_stream_error_generic')
            break
        case MediaError.COUNTRY_BLOCKED:
            message = i18n.global.t('generic.msg_stream_error_blocked')
            break
        case MediaError.NO_TURNOVER_UK:
            message = i18n.global.t('generic.msg_error_stream_turnover')
            break
        case MediaError.NO_TURNOVER:
            message = i18n.global.t('generic.msg_no_turnover', {
                period: 14,
                turnover: formatToLocaleNumberWithCurrency(
                    userStoreService.streamsMinTurnover14d(),
                    userStoreService.currency(),
                    true
                ),
            })

            status = ModalSimpleStatus.WARNING

            break
        case MediaError.TOKEN_NOT_FOUND:
            message = i18n.global.t('generic.msg_stream_difficulties')
            break
        case MediaError.NO_ARCHIVE:
            message = i18n.global.t('generic.msg_error_stream_no_archive')
            break
        case MediaError.EVENT_NOT_STARTED:
            message = i18n.global.t('generic.msg_error_stream_not_started')
            break
        case MediaError.EVENT_OVER:
            message = i18n.global.t('generic.msg_error_stream_ended')
            break
        case MediaError.LOGIN_REQUIRED:
            userStoreService.authRequired(true)
            throw error

        default:
            message = i18n.global.t('generic.msg_stream_error_generic')
    }

    ModalService.getInstance().displayStandardModal(message, '', status)
}
