<script lang="ts">
/**
 * This component is used to satisfy vue router when a dynamic import fails.
 * Otherwise, it will throw an error and log it to sentry.
 */
export default {
    name: 'ObrDynamicImportError',
}
</script>

<template>
    <div></div>
</template>
