import { uiStoreService } from '@obr-core/services/store'
import { getDisplayValueForBetType } from '@obr-core/helpers/display-value.helpers'
import { RUNNER_ROW_SELECTOR_ID } from '@obr-ui/components/RunnerTable/config'
import { OBRDocument } from '@obr-core/helpers'
import { ToteGateway } from '@obr-core/config/race'
import { i18n } from '@obr-core/i18n/i18n'

export function renderMarketLabel(
    betType: OBR.Betting.MarketBetType,
    race: OBR.Race.Race | null
): string {
    if (!race) return ''

    return i18n.global.t(
        getDisplayValueForBetType(
            betType,
            race.country,
            race.bet_types,
            race.tote_gateway === ToteGateway.EGT
        )
    )
}

// Add bet effect when bet is added to betslip
// Find querySelector table row => `#runner-row-${row.id}` and add class `bet-added`
export function renderAddToBetslipAnimation(idRunner: string) {
    const tableRow = OBRDocument().querySelector<HTMLTableRowElement>(
        `#${RUNNER_ROW_SELECTOR_ID}${idRunner}`
    )

    if (tableRow && uiStoreService.isOnlyMobile()) {
        tableRow.classList.add('bet-added')

        setTimeout(() => {
            tableRow.classList.remove('bet-added')
        }, 1000)
    }
}
