import { useEvents } from '@obr-core/store/events'
import { normalizeRaceByEventSocket } from '@obr-ui/helpers/events.helpers'
import { BetSpecialType } from '@obr-core/config/betting'
import { useRaces } from '@obr-core/store/races'

class EventStoreService {
    eventsCache(): OBR.Common.Object<OBR.Events.Event> {
        return useEvents().eventsCache
    }
    eventByCache(id: string): OBR.Events.Event {
        return useEvents().eventsCache[id]
    }
    events(): OBR.Common.Object<OBR.Events.Event> {
        return useEvents().eventBrowserEvents
    }
    antepostEvents(): OBR.Common.Object<OBR.Events.Event> {
        return useEvents().antepostEvents
    }
    virtualEvents(): OBR.Common.Object<OBR.Events.Event> {
        return useEvents().virtualEvents
    }
    pickBetsCache() {
        return useEvents().pickbetsCache
    }
    pickBetsCacheById(id: string): OBR.Events.Pickbet | undefined {
        return useEvents().pickbetsCache[id]
    }
    pickBetsCacheByEventId(id: string) {
        return Object.values(useEvents().pickbetsCache).filter(
            (pickbet: OBR.Events.Pickbet) => {
                return pickbet.races.some(
                    (race) => String(race.id_event) === id
                )
            }
        )
    }
    specials(): OBR.Events.Event[] {
        // map event object to array and filter only special events
        return Object.values(useEvents().eventsCache).filter(
            (event: OBR.Events.Event) => {
                return event.special_type === BetSpecialType.SPECIAL
            }
        )
    }
    /* Actions */
    loadEventH2H(eventId: string): Promise<OBR.Events.Event> {
        return useEvents().onEventCardLoadH2H(eventId)
    }
    loadEvent(id: string): Promise<OBR.Events.Event> {
        return useEvents().onEventCardLoadEvent(id)
    }
    loadEventSpecial(id: string): Promise<OBR.Events.Event> {
        return useEvents().onEventCardLoadEventSpecial(id)
    }
    loadEventSpecials(): Promise<OBR.Events.Event[]> {
        return useEvents().onEventCardLoadEventSpecials()
    }
    loadEventPickbets(id: string): Promise<OBR.Events.Pickbet[]> {
        return useEvents().loadPickbetsByEventIdAndSetCache(id)
    }
    loadPickbetsByRace(raceId: string): Promise<OBR.Events.Pickbet[]> {
        return useEvents().loadPickbetsByRaceId(raceId)
    }
    getPickbetsByRace(raceId: string): OBR.Events.Pickbet[] {
        return useEvents().getPickbetsByRaceId(raceId)
    }
    async loadEventPickbet(
        pickbetId: string,
        eventId?: string
    ): Promise<OBR.Events.Pickbet | null> {
        return useEvents().onEventCardLoadPickbetById(pickbetId, eventId)
    }
    loadEventBrowserEvents(date: string) {
        return useEvents().onEventBrowserLoadEvents(date)
    }
    loadAntepostEvents() {
        return useEvents().onEventBrowserLoadAntepostEvents()
    }
    loadVirtualEvents() {
        return useEvents().onEventBrowserLoadVirtualEvents()
    }
    // socket section
    updateEventsBySocket(updateEvents: OBR.Common.Object<OBR.Events.Event>) {
        return useEvents().onEventBrowserUpdateEventsBySocket(updateEvents)
    }
    updateAntepostBySocket(updateEvents: OBR.Common.Object<OBR.Events.Event>) {
        return useEvents().onEventBrowserUpdateAntepostBySocket(updateEvents)
    }
    updateVirtualEventsBySocket(
        updateEvents: OBR.Common.Object<OBR.Events.Event>
    ) {
        return useEvents().onEventBrowserUpdateVirtualEventsBySocket(
            updateEvents
        )
    }
    updateEventCartBySocket(
        idEvent: string,
        updateEventCard: OBR.Events.SocketEventCardPayload
    ) {
        if (updateEventCard?.races.length) {
            updateEventCard?.races.forEach((race) =>
                useRaces().onRaceCardUpdateByEventSocket({
                    id: `${race.idRace}`,
                    race,
                })
            )

            return useEvents().onEventUpdateRacesBySocket({
                eventID: idEvent,
                races: normalizeRaceByEventSocket(updateEventCard.races),
            })
        }
    }
}

export const eventStoreService = new EventStoreService()
