<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

export interface Props {
    component?: string
    name: string
}

const props = withDefaults(defineProps<Props>(), {
    component: 'SvgIcon',
    isColored: false,
})

const icon = defineAsyncComponent({
    loader: () => {
        if (props.component === 'SvgIcon') {
            return import(`./assets/${props.name}.svg`)
        } else {
            return import(`../${props.component}/assets/${props.name}.svg`)
        }
    },
    // we are bundling this component as separate chunk so the errorLoadingFile is initialized after the chunk is loaded
    // therefore we need to import the errorLoadingFile dynamically
    onError: (error) =>
        import('@obr-core/router/hooks').then((m) => m.errorLoadingFile(error)),
})
</script>

<template>
    <component :is="icon" class="inline-block"></component>
</template>
