import { i18n } from '@obr-core/i18n/i18n'

import {
    ROUTE_EVENT_CARD_EXTENDED,
    ROUTE_RACE_CARD_EXTENDED,
} from '@obr-core/config/route'
import { routesLocale } from '@obr-core/router/locale-routes'
import { ROUTER_EVENT_LOCATION_VIRTUALS } from '@obr-core/config/router'
import { slugify } from '@obr-core/helpers/router.helpers'
import { getRaceCardLink } from '@obr-ui/helpers/link.helpers'
import { isWebComponent } from '@obr-core/helpers/app.helpers'
import { getDisplayValueForRaceType } from '@obr-core/helpers/display-value.helpers'
import { getIsB2B } from '@obr-core/helpers/brand-settings.helpers'

/**
 * Get Event Extended router link
 *
 * e.g.: /france/happy-valley-pmu/gallop?event=4885721
 */
export function getEventCardExtendedLink(
    payload: OBR.Events.ExtendedLinkPayload,
    queryString?: Record<string, string>
): OBR.Router.RouteLocationRaw {
    const raceType = payload.race_types?.length
        ? payload.race_types[0]
        : ('' as OBR.Race.RaceType)

    let eventLocation = ''

    if (payload.country) {
        eventLocation = slugify(
            i18n.global.t(`generic.countries.${payload.country}`)
        )
    } else {
        eventLocation = slugify(i18n.global.t(`generic.label_virtuals`))
    }

    return {
        name: ROUTE_EVENT_CARD_EXTENDED,
        params: {
            eventLocation: eventLocation,
            eventName: slugify(payload.title),
            raceType: slugify(getDisplayValueForRaceType(raceType)),
            eventID: payload.id,
        },
        query: queryString,
    }
}

/**
 * Get Race Card router link extended
 * ex: /france/happy-valley-pmu/gallop/race-4?race=4885721
 * or fallback old race route
 */
export function getRaceCardLinkExtended(
    raceId: string | number,
    country?: string,
    eventTitle?: string,
    raceType?: OBR.Race.RaceType,
    raceNumber?: number | string,
    query?: OBR.Router.LocationQueryRaw
): OBR.Router.RouteLocationRaw {
    if (country && eventTitle && raceType && raceNumber) {
        const params = {
            eventLocation:
                country === ROUTER_EVENT_LOCATION_VIRTUALS
                    ? ROUTER_EVENT_LOCATION_VIRTUALS
                    : slugify(i18n.global.t(`generic.countries.${country}`)),
            eventName: slugify(eventTitle),
            raceType: slugify(getDisplayValueForRaceType(raceType)),
            raceNumber: slugify(
                i18n.global.t('generic.label_race_number', {
                    number: raceNumber,
                })
            ),
            raceID: raceId,
        }

        return {
            name: ROUTE_RACE_CARD_EXTENDED,
            params,
            query,
        }
    }
    return getRaceCardLink(raceId, query)
}

/**
 * Get Horse Formguide Full Extended router link
 *
 * e.g.: /formguide/1789364/overview/5419670
 */
export function getFormguideExtendedLink(
    idSubject: number | string,
    idRace: number | string = ''
) {
    let origin = ''

    if (isWebComponent() && !getIsB2B()) {
        origin = window.location.origin + '/popup'
    }

    return `${origin}/${routesLocale.formguide}/${idSubject}/${
        routesLocale['formguide_overview']
    }${idRace ? `/${idRace}` : ''}`
}

/**
 * Get Horse Formguide Full Extended router link
 *
 * e.g.: /en/formguide/1789364/overview/5419670
 */
export function getFormguideTabExtendedLink(
    idSubject: number | string,
    idRace: number | string = '',
    language: string,
    tab: string
) {
    let origin = `/${language}`

    if (isWebComponent() && !getIsB2B()) {
        origin += '/popup'
    }

    return `${origin}/${routesLocale.formguide}/${idSubject}/${
        tab ? tab : routesLocale['formguide_overview']
    }${idRace ? `/${idRace}` : ''}`
}

/**
 * Get Popup External Stream Link
 *
 * e.g.: /media/1/
 */
export function getPopupExternalStreamLink(idPopup: number | string) {
    let origin = ''

    if (isWebComponent() && !getIsB2B()) {
        origin = window.location.origin + '/popup'
    }

    return `${origin}/${routesLocale['media']}/${idPopup}/`
}
