/**
 * Show error modal event
 */
export const EMITTER_STANDARD_MODAL = 'EMITTER_STANDARD_MODAL'

/**
 * Show confirmation modal event
 */
export const EMITTER_CONFIRMATION_MODAL = 'EMITTER_CONFIRMATION_MODAL'

/**
 * Show close confirmation modal event
 */
export const EMITTER_CLOSE_CONFIRMATION_MODAL =
    'EMITTER_CLOSE_CONFIRMATION_MODAL'

/**
 * Remove all stakes from singles bets
 */
export const EMITTER_REMOVE_SINGLE_STAKES = 'EMITTER_REMOVE_SINGLE_STAKES'

/**
 * Remove all stakes from multiple bets
 */
export const EMITTER_REMOVE_MULTI_STAKES = 'EMITTER_REMOVE_MULTI_STAKES'

/**
 * Bet placed
 */
export const EMITTER_BET_PLACED = 'EMITTER_BET_PLACED'

/**
 * Websocket bet status update
 *
 * i.e. update open bets or bet status for logged in user
 */
export const EMITTER_WEBSOCKET_BET_STATUS = 'EMITTER_WEBSOCKET_BET_STATUS'
export const EMITTER_OPEN_DESIGN_MODAL = 'EMITTER_OPEN_DESIGN_MODAL'

/**
 * Webcomponent events for B2B integration.
 */
export const EMITTER_WEBCOMPONENT_LOGIN = 'login'
export const EMITTER_WEBCOMPONENT_SESSION_EXPIRED = 'sessionExpired'
export const EMITTER_WEBCOMPONENT_REGISTER = 'register'
export const EMITTER_WEBCOMPONENT_GO_TO_RACECARD = 'goToRaceCard'
export const EMITTER_WEBCOMPONENT_REQUEST_SPORTSBOOK = 'requestSportsbook'
export const EMITTER_WEBCOMPONENT_NAVIGATE = 'navigate'
export const EMITTER_WEBCOMPONENT_NAVIGATE_MOBILE = 'navigateMobile'
export const EMITTER_WEBCOMPONENT_SHOW_DEPOSIT = 'showDeposit'
export const EMITTER_WEBCOMPONENT_MOUNTED = 'mounted'
export const EMITTER_WEBCOMPONENT_CREATED = 'created'
export const EMITTER_WEBCOMPONENT_OPEN_STREAMS = 'openStreams'
export const EMITTER_WEBCOMPONENT_OPEN_FORMGUIDE = 'openFormguide'
export const EMITTER_WEBCOMPONENT_OPEN_FEEDBACK_DESIGN_MODAL =
    'openFeedbackDesignModal'
export const EMITTER_WEBCOMPONENT_BET_SUCCESS = 'betSuccess'
export const EMITTER_WEBCOMPONENT_PAGE_RELOAD = 'pageReload'
export const EMITTER_WEBCOMPONENT_CHANGE_VERSION = 'changeVersion'
