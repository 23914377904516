import { Provider, StreamType } from '@obr-core/config/media'
import { getPopupExternalStreamLink } from './link-extended.helpers'
import { open } from './popup-window'

export function openStreamPopup(options: {
    idChannel: string
    streamType: OBR.Streams.StreamType
    provider: OBR.Streams.Provider
    idRace?: string
}): void {
    const link = getPopupExternalStreamLink(
        options.idRace ? options.idRace : options.idChannel
    )

    const windowName = `popup-stream-${
        options.idRace ? options.idRace : options.idChannel
    }${['FLV', 'MP4'].includes(options.streamType) ? '-a' : ''}`

    const settings = {
        height: 310,
        width: 570,
        top: window.innerHeight / 2,
        left: window.innerWidth / 2,
        resizable: 'yes',
        scrollbars: 'yes',
    }

    if (
        options.provider === Provider.PFG &&
        options.streamType === StreamType.LIV
    ) {
        settings.height = 584
        settings.width = 800
    }

    const windowConfig = Object.entries(settings)
        .map(([key, value]) => `${key}=${value}`)
        .join(',')

    open({
        url: link,
        height: settings.height,
        width: settings.width,
        name: windowName,
        windowConfig,
    })
}
