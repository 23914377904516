import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 472 234"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#F9F9F9",
      d: "M90.353 66.7c-31.723 0-46.038 19.036-46.038 19.036V0H0v231.288h40.876v-17.04s15.228 19.212 48.306 19.212 76.495-25.832 76.495-82.386c0-56.641-43.594-84.373-75.316-84.373zm-8.247 130.324c-11.51 0-41.694-7.975-41.694-45.589 0-37.702 29.544-46.494 41.694-46.494s40.876 8.335 40.876 46.038c0 37.79-29.367 46.038-40.876 46.038z"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#C9AF74",
      d: "M260.927 0h-36.34c0 43.866-43.954 44.05-43.954 44.05v29.182h34.889v160.596h45.405zM471.368 116.553S473.812 0 386.081 0c-87.82 0-84.646 118.181-84.646 118.181s-1.09 115.007 85.647 115.007c86.731-.089 84.286-116.642 84.286-116.642zm-84.646 76.856c-42.776 0-42.231-75.766-42.231-75.766s-1.54-77.85 41.781-77.85 42.143 76.856 42.143 76.856 1.09 76.767-41.693 76.767z"
    }, null, -1)
  ])))
}
export default { render: render }