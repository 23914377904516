import { HTTP_REST_V2 } from '@obr-core/config/http'
import { http } from '@obr-core/lib/http'
import { onStreamsUserErrors } from '@obr-core/errors/streams.errors'
import { ErrorService } from '@obr-core/services/ErrorService'

/**
 * Streams Resource used for all endpoints related to streams
 */
export class StreamsResource {
    private readonly streamsPath = `${HTTP_REST_V2}/streams`

    /**
     * Get Today's streams list
     *
     * e.g. open streams list
     */
    public async getAllStreams() {
        try {
            const response = await http.get<OBR.Streams.StreamsResponse>(
                this.streamsPath
            )

            return response.data.channels || []
        } catch (error: any) {
            ErrorService.getInstance().preventDefault(error)

            onStreamsUserErrors(error.response?.data?.error?.message || '')
        }

        return []
    }

    /**
     * Get stream resource
     *
     * e.g. play specific stream channel
     */
    public async getStreamResource(payload: OBR.Streams.StreamResourceDto) {
        try {
            const url = `${this.streamsPath}/resources`
            const response =
                await http.post<OBR.Streams.StreamResourceResponse>(
                    url,
                    payload
                )

            return response.data
        } catch (error: any) {
            ErrorService.getInstance().preventDefault(error)

            onStreamsUserErrors(error.response?.data?.error?.message || '')
        }

        return null
    }

    /**
     * Close streams or stop media stream
     */
    public async closeStreams(requestID: number) {
        const url = `${this.streamsPath}/close`
        const response = http.put(url, { request_id: requestID })

        return response
    }

    /**
     * Get stream channel upcoming races
     *
     * e.g. displayed in stream list dropdown of races
     */
    public async getStreamRaces(idChannel: string) {
        try {
            const url = `${this.streamsPath}/channels/${idChannel}`
            const response = await http.get<OBR.Streams.StreamRacesResponse>(
                url
            )

            return response.data.races
        } catch (error: any) {
            ErrorService.getInstance().preventDefault(error)
        }

        return []
    }
}
