import { createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  id: "Layer_2",
  viewBox: "0 0 887.1 150.5"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("defs", null, [
      (_openBlock(), _createBlock(_resolveDynamicComponent("style"), null, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(".cls-1{fill:#0067b9;stroke-width:0}")
        ])),
        _: 1
      }))
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("g", { id: "Layer_1-2" }, [
      _createElementVNode("path", {
        d: "M93 121.2H46.7L38 147.8H0L53.1 2.3h34.3l51.8 145.5h-9.9c-16.6 0-31.3-10.7-36.3-26.6m-8.9-31.9L70.3 46.2 55.9 89.3zM146.6 147.8V39.1h33.3v13.4c6.9-10.8 16.5-16.3 28.9-16.3 4.7 0 9.3 1 13.6 3l-2.8 33.1c-5.1-1.8-9.8-2.6-14.4-2.6-15.9 0-24 10.4-24 31.3v46.8zM239.5 134.2c-10.6-10.6-15.9-24.2-15.9-40.9s5.3-30.3 16.1-40.9 24.8-15.9 42.1-15.9c22.6 0 42.5 11 51.8 31.7l-27.8 14.6c-5.5-9.3-13.2-14-23.2-14-7.3 0-13.2 2.4-17.9 7.1-4.5 4.7-6.9 10.6-6.9 17.5 0 14.4 10.4 25 24.4 25 10 0 19.3-5.5 23.2-14l27.8 16.5c-9.4 18.1-28.9 29.3-52 29.3-17.1 0-30.9-5.4-41.7-16M452.4 147.8h-34.8V52.2h34.8zM478.9 134.2C468.3 123.6 463 110 463 93.3s5.3-30.3 16.1-40.9 24.8-15.9 42.1-15.9c22.6 0 42.5 11 51.8 31.7l-27.8 14.6c-5.5-9.3-13.2-14-23.2-14-7.3 0-13.2 2.4-17.9 7.1-4.5 4.7-6.9 10.6-6.9 17.5 0 14.4 10.4 25 24.4 25 10 0 19.3-5.5 23.2-14l27.8 16.5c-9.4 18.1-28.9 29.3-52 29.3-17.1 0-30.9-5.4-41.7-16M701.4 104.9c0 12.4-4.3 22.6-13 30.7s-19.9 12.2-33.9 12.2H583V2.3h68.1c13.4 0 23.8 3.5 31.3 10.8 7.5 7.1 11.2 15.6 11.2 25.2 0 12.8-8.1 25.4-19.9 29.5 18 4.6 27.7 19 27.7 37.1m-82.3-71.5V58h23.6c10.2 0 15-5.5 15-12.4 0-6.5-4.9-12.2-13.4-12.2zm27.6 82.9c11.6 0 16.9-6.7 16.9-14.4 0-8.1-5.7-14.6-16.1-14.6h-28.3v28.9h27.5zM819.8 104.7h-77.6c3.9 11.4 12.8 17.1 26.6 17.1 12 0 22.8-3.3 32.9-10l13.6 23.4c-14 10-29.9 15-48 15-19.3 0-34.1-5.5-44.5-16.3-10.4-11-15.6-24.4-15.6-40.4s5.3-29.9 16.1-40.7 24.8-16.3 41.9-16.3c16.1 0 29.5 4.9 40 14.6 10.4 9.6 15.7 23 15.7 39.8.1 4.3-.3 8.9-1.1 13.8m-77.9-23.8H788c-3-10.4-11-16.7-22.6-16.7-12.1-.1-19.8 5.4-23.5 16.7M452.4 45.3c-19.2 0-34.8-15.6-34.8-34.8V2.4h34.8zM887 117.2c-3.4 1.1-6.5 1.6-9.5 1.6-9.3 0-13.8-5.7-13.8-17.3V67.8H887V39.1h-23.3V.5h-34.4l-.4 105.1c0 29.9 13.4 44.9 40.4 44.9 6.3 0 12.2-.7 17.8-2.1v-31.2zM401.5 116.7c-3.4 1.1-6.5 1.6-9.5 1.6-9.3 0-13.8-5.7-13.8-17.3V67.3h23.3V38.6h-23.3V0h-34.4l-.4 105.1c0 29.9 13.4 44.9 40.4 44.9 6.3 0 12.2-.7 17.8-2.1v-31.2z",
        class: "cls-1"
      })
    ], -1))
  ]))
}
export default { render: render }