import {
    BRAND_SETTINGS_EXTERNAL_LINKS_FALLBACK_LANG,
    BRAND_SETTINGS_POPULAR_COUNTRIES,
    BRAND_SETTINGS_HOST_URI,
} from '@obr-core/config/brand-settings'

function getLink(type: string, lang: string) {
    const EXTERNAL_LINKS = document.obrConfig?.external_links

    return EXTERNAL_LINKS[type]?.[lang]
        ? EXTERNAL_LINKS[type][lang]
        : EXTERNAL_LINKS[type][BRAND_SETTINGS_EXTERNAL_LINKS_FALLBACK_LANG]
}

export function getExternalLink(type: string, language: string): string {
    const EXTERNAL_LINKS = document.obrConfig?.external_links || {}

    if (EXTERNAL_LINKS?.[type]) {
        return getLink(type, language)
    }

    return '#'
}

export function getIsB2B() {
    if (document.obrConfig && 'isB2B' in document.obrConfig) {
        return document.obrConfig.isB2B
    }

    return true
}

/*
 * Get popular countries that are use in filtering upcoming races
 */
export function getPopularCountries(language: string): string[] {
    if (document.obrConfig && document.obrConfig.popular_countries) {
        return (
            document.obrConfig[BRAND_SETTINGS_POPULAR_COUNTRIES][language] ||
            document.obrConfig[BRAND_SETTINGS_POPULAR_COUNTRIES].default
        )
    }

    return []
}

/**
 * Get host uri
 */
export function getHostUri(language: string) {
    if (document.obrConfig && document.obrConfig.host_uri) {
        const URI = document.obrConfig[BRAND_SETTINGS_HOST_URI]
        return URI[language] || URI.default
    }

    return ''
}

/**
 * Get million host uri
 */
export function getMillionHostUri(language: string) {
    if (document.millionConfig && document.millionConfig.host_uri) {
        const URI = document.millionConfig[BRAND_SETTINGS_HOST_URI]
        return URI[language] || URI.default
    }

    return ''
}
