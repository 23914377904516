import { ResizeObserver } from '@juggle/resize-observer'

let resizeObserver: ResizeObserver

export const resize: OBR.Global.ObjectDirective = {
    mounted: (el, binding) => {
        resizeObserver = new ResizeObserver(() => {
            binding.value()
        })
        resizeObserver.observe(el)
    },
    unmounted: (el) => {
        resizeObserver.unobserve(el)
    },
}
