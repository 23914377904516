import { fromUnixTime, intervalToDuration, differenceInDays } from 'date-fns'
import { i18n } from '@obr-core/i18n/i18n'
import {
    formatToLocaleNumber,
    formatToLocaleNumberWithCurrency,
} from '@obr-core/utils/number.format'
import {
    formatDate,
    formatDateISO,
    isToday,
    isTomorrow,
    isYesterday,
} from '@obr-core/helpers/date.helpers'
import { compareDates } from '@obr-core/helpers/date.helpers'
import {
    DT_ONE_HOUR,
    DT_ONE_MINUTE,
    DT_TWELVE_HOUR,
} from '@obr-core/config/date-time'
import {
    FD_DAYS_AND_MONTHS,
    FD_DAY_NUMBER_AND_MONTH_THREE_LETTERS,
    FD_DAY_OF_WEEK_LONG,
    FD_DAY_OF_WEEK_TWO_LETTERS,
    FD_HOURS_AND_MINUTES,
    FD_LOCALIZED_DATE_LONG,
    FD_LOCALIZED_DATE_MEDIUM,
    FD_LOCALIZED_DATE_SHORT,
    FD_LOCALIZED_DATE_SHORT_WITH_DOTS,
    FD_LOCALIZED_TIME_LONG,
    FD_LOCALIZED_TIME_SHORT,
    FD_LOCALIZED_YEAR,
} from '@obr-core/config/format-date'
import { formatOdds } from '@obr-core/helpers/odds.helpers'
import {
    FixedOddsStatus,
    JackpotType,
    RaceType,
    TrackGoing,
    TrackSurface,
} from '@obr-core/config/race'
import { userStoreService } from '@obr-core/services/store'
import { getPercentage } from '@obr-core/helpers/generic.helpers'
import { getTrackConditionsKeys } from '@obr-ui/helpers/racing.helpers'
import {
    isPickPlace,
    getPickNumRaces,
    isPickBetType,
} from '@obr-core/helpers/betslip.helpers'
import { Currency, OddsFormat } from '@obr-core/config/settings'
import { UnitSystem } from '@obr-core/config/settings'
import {
    BETTING_MARKET_H2H,
    BETTING_MARKET_SPECIAL,
    SIS_COUNTRIES,
    BetType,
    BetCategory,
    BETTING_MARKET_PICKBET,
} from '@obr-core/config/betting'

/**
 * Get display value for race type
 *
 * e.g. "G" in en "Gallop"
 */
export function getDisplayValueForRaceType(race_type: string) {
    return i18n.global.t(`generic.label_race_type_${race_type.toLowerCase()}`)
}

/**
 * Get display value for distance
 *
 * e.g. 2,000 m
 */
export function getDisplayValueForDistance(
    distance: number,
    unitSystem: UnitSystem
): string {
    if (unitSystem === UnitSystem.IMPERIAL) {
        let distanceFormatted = ''
        const yards = Math.round(distance / 0.9144)
        const furlong = Math.floor(yards / 220)
        const miles = Math.floor(furlong / 8)

        if (miles > 0) {
            distanceFormatted +=
                miles + i18n.global.t('generic.label_miles_short')
        }

        if (furlong % 8 > 0) {
            distanceFormatted +=
                Math.floor(furlong % 8) +
                i18n.global.t('generic.label_furlong_short')
        }

        if (yards % 220 > 0) {
            distanceFormatted +=
                Math.floor(yards % 220) +
                i18n.global.t('generic.label_yards_short')
        }

        return distanceFormatted
    }
    return `${formatToLocaleNumber(distance)} m`
}

/**
 * Get display value for weight
 *
 * e.g. "3kg" or "11-9"
 */
export function getDisplayValueForWeight(
    weight: number,
    unitSystem: UnitSystem
): string {
    if (unitSystem === UnitSystem.IMPERIAL) {
        const basePounds = Math.round(weight / 0.45359237) // convert to pounds
        const stones = Math.floor(basePounds / 14) // convert to stones
        const pounds = Math.round(basePounds % 14)

        return stones + '-' + pounds
    }
    return i18n.global.t('racing.label_weight_x_kg', {
        weight,
    })
}

/**
 * Get display value for race category
 *
 * e.g. "HC3" in en "Handicap 3"
 */
export function getDisplayValueForRaceCategory(
    category: string,
    isGreyhoundRace = false
) {
    if (!category) {
        return '-'
    }

    if (isGreyhoundRace) {
        return category
    }

    return i18n.global.t(`racing.label_race_category_${category.toUpperCase()}`)
}

/**
 * Get display value for race category and letter
 *
 * e.g. "Handicap (Class 5)"
 */
export function getDisplayValueForRaceCategoryAndLetter(
    race: OBR.Race.Race,
    eventLocation: string = 'GB'
) {
    let category = ''
    if (race.race_type !== RaceType.GREYHOUND && race.category) {
        category = getDisplayValueForRaceCategory(race.category)
        if (race.category_letter) {
            category += ` (${getDisplayValueForRaceCategoryLetterV2(
                race.category_letter,
                eventLocation
            )})`
        }
    }

    if (race.race_type === RaceType.GREYHOUND && race.category_letter) {
        category += ` (${getDisplayValueForRaceCategoryLetterV2(
            race.category_letter,
            eventLocation
        )})`
    }

    return category
}

/**
 * Get display value abbr for race category
 *
 * e.g. "HC3" in en "Hcp 3"
 */
export function getDisplayValueForRaceCategoryAbbr(category: string) {
    if (!category) {
        return '-'
    }

    return i18n.global.t(
        `racing.label_race_category_abbr_${category.toUpperCase()}`
    )
}

/**
 * Get display value for race type detail
 *
 * e.g. "FLT" in en "Flat Race"
 */
export function getDisplayValueForRaceTypeDetail(detail: string) {
    if (!detail) {
        return ''
    }

    return i18n.global.t(
        `racing.label_race_type_detail_${detail.toUpperCase()}`
    )
}

/**
 * Get display value for post position
 *
 * e.g. "3.4" or "" in case when undefined or program number and post position are the same
 */
export function getDisplayValueForPostPosition(postPosition?: number | null) {
    if (postPosition) {
        return `${postPosition}`
    }

    return ''
}

/**
 * Get display value for race category letter version 2
 *
 * e.g. "5" in en "Class 5" used in Event Card details
 */
export function getDisplayValueForRaceCategoryLetterV2(
    letter: string,
    location: string
) {
    switch (location) {
        case 'FR':
            return i18n.global.t('racing.label_race_category_letter_france', {
                letter,
            })
        case 'GB':
        case 'IE':
        case 'AE':
            return i18n.global.t('racing.label_race_category_letter_english', {
                letter,
            })
        default:
            return letter
    }
}

/**
 * Get display value for jackpot type
 *
 * e.g. "SUP" in en "Super"
 */
export function getDisplayValueForJackpotType(type: string) {
    return i18n.global.t(`racing.label_jackpot_type_${type.toUpperCase()}`)
}

/**
 * Get display value for bet type
 *
 * e.g. "WIN" in en "Win"
 */
export function getDisplayValueForBetType(
    betType: string,
    raceCountry?: string,
    betTypes?: OBR.Race.BetType[],
    isEgtTOT?: boolean
) {
    if (!betType) return i18n.global.t('generic.label_all_bet_types')
    if (betType === BETTING_MARKET_PICKBET) {
        return i18n.global.t('racing.label_pickbets_races')
    } else if (isPickBetType(betType)) {
        return getDisplayPickbetTitle(betType)
    } else if (BETTING_MARKET_H2H === betType) {
        return i18n.global.t('generic.h_head_to_heads')
    } else if (BETTING_MARKET_SPECIAL === betType) {
        return i18n.global.t('generic.label_specials')
    } else {
        if (raceCountry && betTypes) {
            return i18n.global.t(
                getMarketLabel(
                    betType.toUpperCase(),
                    raceCountry,
                    betTypes,
                    isEgtTOT
                )
            )
        }

        return i18n.global.t(`betting.label_bet_type_${betType.toUpperCase()}`)
    }
}

/**
 * The function checks if the betType is either EXACTA or TRIFECTA.
 * It also checks if the Race Country is included in the SIS_COUNTRIES array.
 * If EXACTA or TRIFECTA has only one bookie category, it returns the BOKUK extended label
 */
export function getMarketLabel(
    betType: string,
    raceCountry: string,
    betTypes: OBR.Race.BetType[],
    isEgtTot?: boolean
) {
    if (isForecastOrTricast(betType, raceCountry, betTypes)) {
        return `betting.label_bet_type_${betType}BOKUK`
    }

    if (betType === BetType.EACHWAY && isEgtTot) {
        return `generic.label_bet_type_WPUS`
    }

    return `betting.label_bet_type_${betType}`
}

/**
 * Checks if the given bet type is either Exacta or Trifecta.
 * e.g. Used as a helper function in getMarketLabel
 */
export function isForecastOrTricast(
    betType: string,
    raceCountry: string,
    betTypes: OBR.Race.BetType[]
) {
    if (betType !== BetType.EXACTA && betType !== BetType.TRIFECTA) {
        return false
    }

    //we get BOK EXA/TRI odds only from SIS/PA
    if (!SIS_COUNTRIES.includes(raceCountry)) {
        return false
    }

    const betCategories = betTypes.find(
        (item) => item.bet_type === betType
    )?.categories

    //if Tote is enabled, we use Tote pool odds
    if (betCategories?.includes(BetCategory.TOTE)) {
        return false
    }

    return true
}

/**
 * Get display value for odds
 *
 * e.g. "4.1" or "Bet"
 */
export function getDisplayValueForOdds(
    fixedOddsStatus: OBR.Race.FixedOddsStatus,
    FXW: number = 0,
    PRC: number = 0,
    oddsFormat: OddsFormat
) {
    const odds = fixedOddsStatus !== FixedOddsStatus.NOT_AVAILABLE ? FXW : PRC

    if (odds > 0) {
        return `${formatOdds(odds, oddsFormat)}`
    }

    return i18n.global.t('generic.label_bet')
}

/**
 * Get display value for bet status
 *
 * e.g. "OPN" in en "Open"
 */
export function getDisplayValueForBetStatus(status: string) {
    return i18n.global.t(`betting.label_bet_status_${status.toLowerCase()}`)
}

/**
 * Get display value for bet category
 *
 * e.g. "TOT" in en "Tote"
 */
export function getDisplayValueForBetCategory(category: string) {
    // TODO remove this afret wi swich to PS and rename label_bet_category_TOTO to label_bet_category_TOT
    // we keep old label_bet_category_TOT only for legacy mobile
    const cat = category === BetCategory.TOTE ? category + 'O' : category
    return i18n.global.t(`betting.label_bet_category_${cat.toUpperCase()}`)
}

/**
 * Get display value for operational detail
 *
 * e.g. "STK" in en "Stake"
 */
export function getDisplayValueForOperationalDetail(stake: string) {
    return i18n.global.t(
        `betting.label_operation_detail_${stake.toUpperCase()}`
    )
}

/**
 * Get display value for selections
 *
 * e.g. "1 2 3" to "1-2-3"
 */
export function getDisplayValueForSelections(selections: string) {
    return selections.split(' ').join('-')
}

/**
 * Get display value column
 *
 * e.g. "2" to "II"
 */
export function getDisplayValueForColumn(column: string) {
    switch (column) {
        case '1':
            return 'I'
        case '2':
            return 'II'
        case '3':
            return 'III'
        case '4':
            return 'IV'
        case '5':
            return 'V'
        case 'c':
            return 'C'
    }

    return column
}

export function getDisplayValueForMarkDetails(
    markDetails?: OBR.MyBets.MarkDetail[]
): string {
    if (!markDetails) return ''

    return markDetails.map(({ name }) => name).join(' - ')
}

/**
 * Get display value for jockey abbr
 *
 * e.g. J: Viola Mitchell
 */
export function getDisplayValueForJockeyAbbr(jockey: string) {
    return jockey
        ? `${i18n.global.t('generic.label_abbr_jockey')}: ${jockey}`
        : `${i18n.global.t('generic.label_abbr_jockey')}: -`
}

/**
 * Get display value for trainer abbr
 *
 * e.g. T: Gavin Scott
 */
export function getDisplayValueForTrainerAbbr(trainer: string) {
    return trainer
        ? `${i18n.global.t('generic.label_abbr_trainer')}: ${trainer}`
        : `${i18n.global.t('generic.label_abbr_trainer')}: -`
}

/**
 * Get display value for race number
 *
 * e.g. Race 1
 */
export function getDisplayValueForRaceNumber(number: number | string) {
    return i18n.global.t('generic.label_race_number', { number })
}

/**
 * Get display value for leg number
 *
 * e.g. Leg 1
 */
export function getDisplayValueForLegNumber(number: number | string) {
    return i18n.global.t('generic.label_leg_number', { number })
}

/**
 * Get display value for race number (short)
 *
 * e.g. R1
 */
export function getDisplayValueForRaceNumberShort(number: number | string) {
    return i18n.global.t('generic.label_race_number_short', { number })
}

/**
 * Get display value for leg number (short)
 *
 * e.g. L1
 */
export function getDisplayValueForLegNumberShort(number: number | string) {
    return i18n.global.t('generic.label_leg_number_short', { number })
}

/**
 * Get display value for time long
 *
 * e.g. 14:30:03
 * @param timestamp in seconds
 */
export function getDisplayValueForTimeLong(timestamp: number) {
    return formatDate(timestamp * 1000, FD_LOCALIZED_TIME_LONG)
}

/**
 * Get display value for time
 *
 * e.g. 14:30
 * @param timestamp in seconds
 */
export function getDisplayValueForTime(timestamp: number) {
    return formatDate(timestamp * 1000, FD_LOCALIZED_TIME_SHORT)
}

/**
 * Get display value for time
 *
 * e.g. 17/06/2022
 * @param timestamp in seconds
 */
export function getDisplayValueForDate(timestamp: number) {
    return formatDate(timestamp * 1000, FD_LOCALIZED_DATE_SHORT)
}

/**
 * Get display value for date and hour
 *
 * e.g. 17/06/2022 14:30
 * @param timestamp in seconds
 */
export function getDisplayValueForDateAndHour(timestamp: number) {
    return `${formatDate(
        timestamp * 1000,
        FD_LOCALIZED_DATE_SHORT
    )} ${formatDate(timestamp * 1000, FD_LOCALIZED_TIME_SHORT)}`
}

/**
 * Get display value for date and time long
 *
 * e.g. 17/06/2022 14:30:03
 * @param timestamp in seconds
 */
export function getDisplayValueForDateAndTimeLong(timestamp: number) {
    return `${formatDate(
        timestamp * 1000,
        FD_LOCALIZED_DATE_SHORT
    )} ${formatDate(timestamp * 1000, FD_LOCALIZED_TIME_LONG)}`
}

/**
 * Get display value for time with dots
 *
 * e.g. 17.06.2022
 * @param timestamp in seconds
 */
export function getDisplayValueForDateWithDots(timestamp: number) {
    return formatDate(timestamp * 1000, FD_LOCALIZED_DATE_SHORT_WITH_DOTS)
}

/**
 * Get display value for date short
 *
 * e.g. 22 Nov
 */
export function getDisplayValueForDateShort(timestamp: number) {
    return formatDate(timestamp * 1000, FD_DAY_NUMBER_AND_MONTH_THREE_LETTERS)
}

/**
 * Get display value for days last
 *
 * e.g. 12
 */
export function getDisplayValueDaysLast(timestamp: number) {
    return differenceInDays(new Date(), new Date(timestamp * 1000))
}

/**
 * Get display value for date ISO
 *
 * e.g. 2022-11-22
 */
export function getDisplayValueForDateISO(timestamp: number) {
    return formatDateISO(new Date(timestamp * 1000))
}

/**
 * Get display value days and months
 * e.g. 06/02
 * @param timestamp in seconds
 */
export function getDisplayValueForDaysAndMonths(timestamp: number) {
    return formatDate(timestamp * 1000, FD_DAYS_AND_MONTHS)
}

/**
 * Get display value for date with hours and minutes
 *
 * e.g.
 *  - 12:11 · 17/06/2022
 *  - 12:11 · Today
 *  - 12:11 · Tomorrow
 *  - 12:11 · Yesterday
 * @param timestamp in seconds
 */
export function getDisplayValueForHoursMinutesAndDate(timestamp: number) {
    const timestampInMs = timestamp * 1000
    let result: string = `${formatDate(timestampInMs, FD_HOURS_AND_MINUTES)} · `

    if (isToday(timestampInMs)) {
        result += i18n.global.t('generic.label_today')
    } else if (isTomorrow(timestampInMs)) {
        result += i18n.global.t('generic.label_tomorrow')
    } else if (isYesterday(timestampInMs)) {
        result += i18n.global.t('generic.label_yesterday')
    } else {
        result += formatDate(timestampInMs, FD_LOCALIZED_DATE_SHORT)
    }

    return result
}

/**
 *
 * Get display value for date picker input
 *
 * e.g. used in event browser or my bets advanced filters
 *
 * i.e. default = "Mo, 24 Oct 2022"
 * i.e. short = "24 Oct 2022"
 * i.e. long = "Today, 24 October, 2022"
 *
 * @param timestamp in seconds
 * @param type default | short | long
 *
 * @returns string
 */
export function getDisplayValueForDatePicker(
    timestamp: number,
    type: 'default' | 'short' | 'long' | 'month-year' = 'default'
): string {
    let fullDate = ''

    switch (type) {
        case 'short':
            fullDate = formatDate(timestamp, FD_LOCALIZED_DATE_MEDIUM)
            break
        case 'long':
            {
                const day = isToday(timestamp)
                    ? i18n.global.t('generic.label_today')
                    : formatDate(timestamp, FD_DAY_OF_WEEK_LONG)

                fullDate = `${day}, ${formatDate(
                    timestamp,
                    FD_LOCALIZED_DATE_LONG
                )}`
            }
            break
        case 'month-year':
            fullDate = `${formatDate(timestamp, 'LLLL')} ${formatDate(
                timestamp,
                FD_LOCALIZED_YEAR
            )}`
            break
        default: {
            const day = formatDate(timestamp, FD_DAY_OF_WEEK_TWO_LETTERS)
            const date = formatDate(timestamp, FD_LOCALIZED_DATE_MEDIUM)
            fullDate = `${day}, ${date}`
        }
    }

    return fullDate
}

/**
 * Show countdown one hour before race starts
 */
export function isPostTimeCountdown(time: number): boolean {
    const diff = time * 1000 - new Date().getTime()

    return diff > 0 && diff < DT_ONE_HOUR
}

/**
 * Show countdown 12 hours before race starts
 */
export function isPostTimeCountdown12h(time: number): boolean {
    const diff = time * 1000 - new Date().getTime()

    return diff > 0 && diff < DT_TWELVE_HOUR
}

/**
 * If post time is due
 */
export function isPostTimeDue(time: number): boolean {
    const nextRace = new Date(time * 1000)
    const now = new Date()

    return nextRace.getTime() - now.getTime() < DT_ONE_MINUTE
}

/**
 * Get display value of time race start
 *
 * e.g. "Due", 23m, 10:30 or 18.06
 */
export function getDisplayValueOfPostTime(
    currentTimestamp: number,
    time: number,
    isFullTime: boolean = false,
    isTimeOnly: boolean = false
): string {
    const nextRace = new Date(time * 1000).getTime()
    const now = new Date(currentTimestamp * 1000)

    if (isPostTimeDue(time) && !isFullTime) {
        return i18n.global.t('generic.label_due')
    } else if (isPostTimeCountdown(time) && !isFullTime) {
        const diff = time * 1000 - now.getTime()
        const min = Math.floor(diff / 1000 / 60)

        return `${min} ${i18n.global.t('generic.time_letter_minutes')}`
    }

    return formatDate(
        nextRace,
        compareDates(new Date(nextRace), now) || isTimeOnly
            ? FD_LOCALIZED_TIME_SHORT
            : FD_DAYS_AND_MONTHS
    )
}

/**
 * Get display value of time to race in hours and minutes
 *
 * e.g. 8h 12m, 2d
 *
 * @param currentTime UNIX timestamp
 * @param raceTime UNIX timestamp
 *
 * @returns string
 */
export function geTimeToRace(
    currentTime: number,
    raceTime: number,
    short?: boolean
): string {
    const now = fromUnixTime(currentTime)
    const race = fromUnixTime(raceTime)

    if (now.getTime() >= race.getTime()) {
        return i18n.global.t('generic.label_due')
    }

    const duration = intervalToDuration({ start: now, end: race })

    if (duration.days) {
        return `${duration.days}${i18n.global.t('generic.label_day_short')}`
    }

    if (duration.hours) {
        if (short) {
            return `${
                duration.hours +
                (duration.minutes && duration.minutes > 29 ? 1 : 0)
            }${i18n.global.t('generic.label_hour_short')}`
        }
        return `${duration.hours}${i18n.global.t('generic.label_hour_short')} ${
            duration.minutes
                ? duration.minutes + i18n.global.t('generic.label_minute_short')
                : ''
        }`
    }

    if (duration.minutes) {
        return `${duration.minutes}${
            short
                ? i18n.global.t('generic.time_letter_minutes')
                : i18n.global.t('generic.label_minute_short')
        }`
    }
    if (duration.seconds) {
        return `${duration.seconds}${
            short
                ? i18n.global.t('generic.label_seconds')
                : i18n.global.t('generic.label_second_short')
        }`
    }
    return ''
}

/**
 * Get display value for next race time left in hours or minutes extended
 *
 * e.g. 2 Hours
 * e.g. 20 Minutes
 *
 * @param time UNIX timestamp
 */
export function getDisplayValueForNextRaceTimeLeft(time: number) {
    const nextRace = new Date(time * 1000)
    const now = new Date()

    if (isPostTimeCountdown(time)) {
        const diff = time * 1000 - now.getTime()
        const min = Math.ceil(diff / 1000 / 60)

        return `${min} ${i18n.global.t('generic.label_time_minutes')}`
    }

    const hours = Math.floor(
        (nextRace.getTime() - now.getTime()) / (1000 * 60 * 60)
    )

    return `${hours} ${i18n.global.t('generic.label_hour_p')}`
}

/**
 * Get display value for race outcome
 *
 * e.g. used on Formguide for Form/Jokey/Trainer
 *
 */
export function getDisplayValueForRaceOutcome(
    finalPosition?: number,
    maxResult?: number,
    numRunners = 0
): string {
    const final =
        finalPosition !== undefined && finalPosition >= 0
            ? finalPosition === 0
                ? '-'
                : finalPosition
            : `${(maxResult || 0) + 1}+`
    return `${final} / ${numRunners}`
}

/**
 *
 * print value depend on finalPosition value
 * ex: used on Formguide for Form/Jokey/Trainer
 * @param finalPosition
 * @param subsequentName
 * @param horseDistance
 * @param opponentName
 * @param finalPositionComment
 * @returns {string}
 */
export function getDisplayValueForRaceOutcomeDesc(
    finalPosition: number,
    subsequentName: string = '',
    horseDistance: number,
    opponentName: string = '',
    finalPositionComment: string = ''
): string {
    const distance = horseDistance ? formatToLocaleNumber(horseDistance) : ''
    if (finalPosition === 1) {
        return `${distance} ${subsequentName}`.trim()
    }
    return `${distance} ${opponentName || ''}`.trim()
}

/**
 * print category Letter
 * @param categoryLetterFlag
 * @param country
 * @param categoryLetter
 * @returns {string}
 */
export function getDisplayValueForRaceCategoryLetter(
    categoryLetterFlag: boolean = false,
    country: string,
    categoryLetter: string
): string {
    return categoryLetterFlag && country === 'FR' && categoryLetter !== ''
        ? categoryLetter
        : ''
}

/**
 * return trackCondition display
 * imported from  frontend/public/scripts/lib/racebetsjs/packages/formatjs => trackConditions
 * @param track_going
 * @param track_surface
 * @param country
 * @param abbr
 * @returns
 */
export function getDisplayValueForRaceTrackConditions(
    trackGoing: TrackGoing,
    trackSurface: TrackSurface,
    country: string = '',
    abbr: boolean = true
) {
    let ret = ''
    const currentLanguage = userStoreService.language()
    if (trackGoing) {
        const stringKeys = getTrackConditionsKeys(
            trackGoing,
            trackSurface,
            country,
            currentLanguage
        )

        if (abbr) {
            // return abbreviation skip middle value because is "label_connect_to"
            // i = i + 2 ex: index 0 .. 2
            for (let i = 0; i < stringKeys.length; i = i + 2) {
                ret += i18n.global.t(stringKeys[i]).charAt(0).toUpperCase()
            }
        } else {
            for (let i = 0; i < stringKeys.length; i++) {
                ret += i18n.global.t(stringKeys[i])
                if (i < stringKeys.length - 1) {
                    ret += ' '
                }
            }
        }
    }

    // checks if we have dict element or returns empty string
    return /\S+\.\S+/.test(ret) ? '' : ret
}

/**
 * Get display value for track surface
 *
 * e.g.: "DRT" in en "Sand"
 */
export function getDisplayValueForRaceTrackSurface(trackSurface: TrackSurface) {
    return i18n.global.t(`racing.label_track_surface_${trackSurface}`)
}

/**
 * display start type (only for trot)
 * ex: C -> Car start
 * @param startType
 */
export function getDisplayValueForRaceStartType(startType: OBR.Race.StartType) {
    return i18n.global.t(`racing.label_start_type_${startType}`)
}

/**
 * print percentage value
 * @param partialValue
 * @param totalValue
 * @returns
 */
export function getDisplayValueForRacePercentage(
    partialValue: number = 0,
    totalValue: number
) {
    return Math.round(getPercentage(partialValue, totalValue)) + '%'
}

/**
 * print age formatting ex: For {{age}} y.o.
 */
export function getDisplayValueForAge(ageFrom: number, ageTo: number) {
    if (ageFrom !== 0 && ageTo !== 0 && ageFrom !== ageTo) {
        return i18n.global.t('racing.label_condition_age_abbr_x_to_y', {
            ageFrom: ageFrom,
            ageTo: ageTo,
        })
    } else if (ageFrom !== 0 && ageTo !== 0 && ageFrom === ageTo) {
        return i18n.global.t('racing.label_condition_age_abbr_x', {
            age: ageFrom,
        })
    } else if (ageFrom !== 0) {
        return i18n.global.t('racing.label_condition_age_abbr_x_plus', {
            age: ageFrom,
        })
    } else if (ageTo !== 0) {
        return i18n.global.t('racing.label_condition_age_abbr_up_to_x', {
            age: ageTo,
        })
    }
    return ''
}

/**
 * Get display value for age
 *
 * e.g. "3+ y.o.", used id event card
 */
export function getDisplayValueForAgeShort(ageFrom: number, ageTo: number) {
    if (ageFrom === ageTo) {
        return i18n.global.t('racing.label_year_old_abbr', {
            age: ageFrom,
        })
    } else if (ageFrom > 0 && ageTo === 0) {
        return (
            i18n.global.t('racing.label_year_old_abbr', {
                age: `${ageFrom}`,
            }) + '+'
        )
    } else if (ageFrom > 0 && ageFrom < ageTo) {
        return i18n.global.t('racing.label_year_old_abbr', {
            age: `${ageFrom}—${ageTo}`,
        })
    } else if (ageFrom === 0) {
        return i18n.global.t('racing.label_condition_age_up_to_x', {
            age: `${ageTo}`,
        })
    }
    return i18n.global.t('racing.label_year_old_abbr', {
        age: `${ageTo}`,
    })
}

/**
 * Get display value for purse
 *
 * e.g. "€14,402" or "-"
 */
export function getDisplayValueForPurse(
    purse?: number,
    currency: string = Currency.EUR
) {
    if (purse === undefined || purse === 0 || currency === '') {
        return '-'
    }

    return formatToLocaleNumberWithCurrency(purse, currency, false)
}

/**
 * used in HorseAbroad
 */
export function getDisplayOlympicCode(country: string) {
    const olympicCode = {
        DE: 'GER',
        GB: 'GBR',
        IT: 'ITA',
        FR: 'FRA',
        IE: 'IRL',
        SE: 'SWE',
        BR: 'BRA',
        NO: 'NOR',
        ZA: 'RSA',
        HK: 'HKG',
        US: 'USA',
    }

    return olympicCode[country as keyof typeof olympicCode] || ''
}

export function getDisplayValueForSilkPath(
    extension: string,
    id: string
): string {
    return id ? `/cache/img/silks/${id}.${extension}` : ''
}

/**
 * get capitalize string
 * e.g.
 * - test -> Test
 */
export function toCapitalize(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1)
}

/**
 * Get display value for ordinal number
 *
 * e.g. "1" to "1st", "2" to "2nd" ...
 */
export function getDisplayValueForOrdinalNumber(value: number) {
    const lastDigit = value.toString().slice(-1)
    const restDigit = value.toString().slice(0, -1)

    switch (lastDigit) {
        case '1':
            return `${restDigit}${i18n.global.t('generic.label_ordinal_nr_1')}`
        case '2':
            return `${restDigit}${i18n.global.t('generic.label_ordinal_nr_2')}`
        case '3':
            return `${restDigit}${i18n.global.t('generic.label_ordinal_nr_3')}`
    }

    return i18n.global.t('generic.label_ordinal_nr', { number: value })
}

/*
 * @param placeOddsFactor
 * @param placesNumber
 * @param toteGateway
 * @param toteActivated
 * @param fixedOddsStatus
 */
export function getDisplayValueForPlaceOddsFactorLabel(
    placeOddsFactor: number,
    placesNumber: number,
    toteGateway: string,
    isShort: boolean = false,
    hideFixedOddsLabel: boolean = false
) {
    if (isShort) {
        return `${placesNumber}@1/${placeOddsFactor}`
    }

    let label = `${placesNumber} ${i18n.global.t(
        'racing.label_places'
    )} @1/${placeOddsFactor} ${i18n.global.t('generic.label_odds')}`

    if (toteGateway === 'EGT' && !hideFixedOddsLabel) {
        label = `${i18n.global.t('generic.label_fixed_odds')}: ` + label
    }
    return label
}
/**
 * Eeach way terms display value
 *
 * e.g. E/W: 3@1/5
 */
export function getDisplayValueForEWTerms(
    placeOddsFactor: number,
    placesNumber: number,
    toteGateway: string
) {
    return `${i18n.global.t(
        'generic.label_bet_type_WP_short'
    )}: ${getDisplayValueForPlaceOddsFactorLabel(
        placeOddsFactor,
        placesNumber,
        toteGateway,
        true
    )}`
}

/**
 * Get display value for promotion
 *
 * e.g. "Free Bet Special", "Enhanced Place Terms" or "Money Back Special"
 */
export function getDisplayValueForPromotion(promotion: OBR.Race.RacePromotion) {
    let label = ''

    if (promotion.label) {
        label += promotion.label
    } else {
        label += i18n.global.t(
            `generic.label_promotion_${promotion.promotion_type.replace(
                '-',
                '_'
            )}`
        )
    }

    return label
}

/**
 * Get display vlue for jackpot label
 *
 * e.g. "10% Winning Bonus"
 */
export function getDisplayValueForJackpotLabel(
    jackpot: OBR.Race.RaceJackpot,
    showJackpotType: boolean = false,
    showBetType: boolean = true
) {
    // special case for "DE" country
    if (jackpot.jackpot_type === JackpotType.SUPERFECTA) {
        return getDisplayValueForBetType(JackpotType.SUPERFECTA)
    }

    const results = []

    if (
        jackpot.jackpot_type === JackpotType.WINNING_BONUS ||
        jackpot.jackpot_type === JackpotType.STAKES_BONUS
    ) {
        results.push(`${jackpot.jackpot_amount}%`)
    } else if (jackpot.jackpot_type !== JackpotType.SUPER) {
        results.push(
            getDisplayValueForPurse(
                jackpot.jackpot_amount,
                jackpot.jackpot_currency
            )
        )
    }
    if (
        jackpot.bet_type &&
        [JackpotType.GUARANTEED_PAYOUT, JackpotType.JACKPOT].includes(
            jackpot.jackpot_type
        )
    ) {
        if (showJackpotType) {
            results.push(
                i18n.global.t(
                    `racing.label_jackpot_type_${jackpot.jackpot_type}`
                )
            )
        }
        if (showBetType) {
            results.push(getDisplayValueForBetType(jackpot.bet_type))
        }
    } else {
        results.push(
            i18n.global.t(`racing.label_jackpot_type_${jackpot.jackpot_type}`)
        )
    }

    if (jackpot.jackpot_type === JackpotType.SUPER) {
        results.push(getDisplayValueForBetType(jackpot.bet_type))
    }

    return results.join(' ')
}

/**
 * Get display value for rule 4 deduction
 *
 * e.g. "10% deductions on bets placed between 13:00 and 17:30" or "10% deductions on bets placed before 17:30"
 */
export function getDisplayValueForRule4Deduction(
    deduction: OBR.Race.Rule4Deduction,
    racePostTime: number
) {
    const details: string[] = []

    const isFromSameDay = deduction.date_start
        ? compareDates(
              new Date(deduction.date_start * 1000),
              new Date(racePostTime * 1000)
          )
        : false
    const isTillSameDay = deduction.date_end
        ? compareDates(
              new Date(deduction.date_end * 1000),
              new Date(racePostTime * 1000)
          )
        : false

    if (deduction.date_start && deduction.date_end) {
        details.push(
            i18n.global.t('racing.msg_rule4_runner_deduction', {
                deduction: deduction.deduction,
                from: isFromSameDay
                    ? getDisplayValueForTimeLong(deduction.date_start)
                    : getDisplayValueForDateAndTimeLong(deduction.date_start),
                till:
                    isTillSameDay && isFromSameDay
                        ? getDisplayValueForTimeLong(deduction.date_end)
                        : getDisplayValueForDateAndTimeLong(deduction.date_end),
            })
        )
    } else if (deduction.date_end) {
        details.push(
            i18n.global.t('racing.msg_rule4_runner_deduction_overnight', {
                deduction: deduction.deduction,
                till: isTillSameDay
                    ? getDisplayValueForTimeLong(deduction.date_end)
                    : getDisplayValueForDateAndTimeLong(deduction.date_end),
            })
        )
    }

    if (deduction.name) {
        details.push(
            `(${deduction.name}${deduction.odds ? ` @${deduction.odds}` : ''})`
        )
    }

    if (deduction.waived) {
        details.push(`- ${i18n.global.t('racing.label_waived')}`)
    }

    return details.join(' ')
}

/**
 * Get pickbet Title
 * e.g. Place 6
 */
export function getDisplayPickbetTitle(pickbetType: string, races?: number) {
    const numRaces = races || getPickNumRaces(pickbetType)
    const titleKey = isPickPlace(pickbetType)
        ? 'label_bet_type_ppx'
        : 'label_bet_type_PXX'
    return i18n.global.t(`betting.${titleKey}`, {
        numRaces,
    })
}

/**
 * Get event card race details
 *
 * e.g. "Handicap (Class 5) · 4—6 y.o. · Chase · 2,200 m · 23,000 EUR · 5 runners"
 */
export function getEventCardRaceDetails(
    race: OBR.Race.Race,
    isOnlyMobile: boolean = false
) {
    const details = []

    if (race.race_type !== RaceType.GREYHOUND) {
        // age formatting
        details.push(getDisplayValueForAgeShort(race.age_from, race.age_to))
        // race type detail formatting
        details.push(getDisplayValueForRaceTypeDetail(race.type_detail))
    }

    // purse formatting
    if (race.purse && race.purse_currency) {
        details.push(getDisplayValueForPurse(race.purse, race.purse_currency))
    }

    // distance formatting
    if (race.distance > 0) {
        details.push(
            getDisplayValueForDistance(
                race.distance,
                userStoreService.unitSystem()
            )
        )
    }

    // runners formatting
    if (race.num_runners > 0) {
        details.push(
            `${race.num_runners} ${i18n.global.t('racing.label_starters')}`
        )
    }

    return details
}

/**
 * Get title for runner table for age column
 *
 * e.g. "Age" or "Distance" or "Weight"
 */
export function getTitleForAgeColumn(
    isAntePost: boolean,
    raceType: OBR.Race.RaceType
) {
    if (isAntePost) {
        return i18n.global.t('racing.label_age')
    }

    if (raceType === RaceType.TROT) {
        return i18n.global.t('racing.label_distance')
    }

    if (raceType === RaceType.GREYHOUND) {
        return i18n.global.t('racing.label_record')
    }

    return i18n.global.t('racing.label_weight')
}

/**
 * Get title for runner for Jockey column
 *
 * e.g. "Driver" or "Jockey"
 */
export function getTitleForJockeyColumn(raceType: OBR.Race.RaceType) {
    if (raceType === RaceType.TROT) {
        return i18n.global.t('racing.label_driver')
    } else if (raceType === RaceType.GREYHOUND) {
        return i18n.global.t('racing.label_trainer')
    }
    return i18n.global.t('racing.label_jockey')
}

/**
 * Get race card details
 *
 * e.g. "Hunter Chase · Crs. D (Class 4) · 4+ y.o. · Turf · Soft · 2,232 m"
 */
export function getRaceCardDetails(race: OBR.Race.Race, isMobile = false) {
    const details: string[] = []
    // distance formatting
    if (race.distance > 0) {
        details.push(
            getDisplayValueForDistance(
                race.distance,
                userStoreService.unitSystem()
            )
        )
    }

    if (race.type_detail && RaceType.GREYHOUND !== race.race_type) {
        details.push(getDisplayValueForRaceTypeDetail(race.type_detail))
    }

    // age formatting
    if (
        RaceType.GREYHOUND !== race.race_type &&
        (race.age_from || race.age_to)
    ) {
        details.push(
            isMobile
                ? getDisplayValueForAgeShort(race.age_from, race.age_to)
                : getDisplayValueForAge(race.age_from, race.age_to)
        )
    }

    if (race.track_surface) {
        details.push(getDisplayValueForRaceTrackSurface(race.track_surface))
    }

    if (race.track_going && race.track_surface) {
        details.push(
            getDisplayValueForRaceTrackConditions(
                race.track_going,
                race.track_surface,
                '',
                false
            )
        )
    }

    return details
}

/**
 * Get race card runner details first line - mobile only
 *
 * e.g. "(2-4-6-3-4) · W: 56kg · 4 yo" or "(2-4-6-3-4) · R: 0:29.27"
 */
export function getRaceCardRunnerDetailsFirstLine(
    runner: OBR.Race.Runner,
    unitSystem: UnitSystem
) {
    const details: string[] = []

    if (runner.past_performance) {
        details.push(`(${runner.past_performance.replace(/\s-\s/g, '-')})`)
    }

    if (runner.past_performance_min_time) {
        const prefix = i18n.global.t('generic.label_abbr_record')

        details.push(`${prefix}: ${runner.past_performance_min_time}`)
    }

    if (runner.jockey_weight) {
        const prefix = i18n.global.t('generic.label_abbr_weight')
        const weight = getDisplayValueForWeight(
            runner.jockey_weight,
            unitSystem
        )

        details.push(`${weight}`)
    }

    if (runner.age) {
        details.push(
            i18n.global.t('racing.label_year_old_abbr', {
                age: runner.age,
            })
        )
    }

    return details.join(' · ')
}

/**
 * Get race card runner details second line
 *
 * e.g. "J: M. Doesson · T: N. Long" or "O: M. Doesson · T: N. Long"
 */
export function getRaceCardRunnerDetailsSecondLine(runner: OBR.Race.Runner) {
    const details: string[] = []

    if (runner.owner) {
        const prefix = i18n.global.t('generic.label_abbr_owner')

        details.push(`${prefix}: ${runner.owner}`)
    }

    if (runner.jockey_firstname && runner.jockey_lastname) {
        const prefix = i18n.global.t('generic.label_abbr_jockey')
        const jockey = `${runner.jockey_firstname[0]}. ${runner.jockey_lastname}`

        details.push(`${prefix}: ${jockey}`)
    }

    if (runner.trainer_firstname && runner.trainer_lastname) {
        const prefix = i18n.global.t('generic.label_abbr_trainer')
        const trainer = `${runner.trainer_firstname[0]}. ${runner.trainer_lastname}`

        details.push(`${prefix}: ${trainer}`)
    }

    return details.join(' · ')
}
/**
 * Splits the input string into an array of objects, where each object contains a text and a match status.
 * The match status indicates whether the text matches the provided match text.
 * @param inputString The input string to be split and matched.
 * @param matchText The text to be matched in the input string.
 * @param minLength The minimum length of the match text. Default value is 3.
 * @returns An array of objects, where each object contains a text and a match status.
 *
 * e.g. "1 2 3" to [{text: "1", match: false}, {text: "2", match: true}, {text: "3", match: false}]
 */
export function matchAndSplit(
    inputString: string,
    matchText: string,
    minLength: number = 3
) {
    if (matchText.length < minLength) {
        return []
    }
    const resultArray = []
    const regex = new RegExp(`(${matchText})`, 'gi')
    let match

    while ((match = regex.exec(inputString)) !== null) {
        const startIndex = match.index
        const endIndex = startIndex + match[0].length

        if (startIndex > 0) {
            resultArray.push({
                text: inputString.substring(0, startIndex),
                match: false,
            })
        }

        resultArray.push({ text: match[0], match: true })

        inputString = inputString.substring(endIndex)
        regex.lastIndex = 0 // Reset lastIndex for global search
    }

    if (inputString.length > 0) {
        resultArray.push({ text: inputString, match: false })
    }

    return resultArray
}

/**
 * This function takes a distance parameter and returns a string that represents the distance to a horse.
 * 0.05 - nose
 * 0.1 - short head
 * 0.2 - head
 * 0.25 - short neck
 * 0.3 - neck
 * more then 0.3 - number rounded to quarter
 * e.g. 0.16 => nose, 3.4 => 3½
 */

export function getDisplayValueForDistanceToHorse(distance: number) {
    if (distance > 0.3) {
        const rounded = Math.round(distance * 4) / 4

        const splited = rounded.toString().split('.')
        splited[0] = splited[0] === '0' ? '' : splited[0]
        switch (splited[1]) {
            case '25':
                return `${splited[0]}¼${i18n.global.t(
                    'racing.label_abbr_horse_lengths'
                )}`
            case '5':
                return `${splited[0]}½${i18n.global.t(
                    'racing.label_abbr_horse_lengths'
                )}`
            case '75':
                return `${splited[0]}¾${i18n.global.t(
                    'racing.label_abbr_horse_lengths'
                )}`
            default:
                return `${splited[0]}${i18n.global.t(
                    'racing.label_abbr_horse_lengths'
                )}`
        }
    } else {
        const targets = [
            {
                d: 0.05,
                label: i18n.global.t('racing.label_distance_005_short'),
            },
            {
                d: 0.1,
                label: i18n.global.t('racing.label_distance_01_short'),
            },
            {
                d: 0.2,
                label: i18n.global.t('racing.label_distance_02_short'),
            },
            {
                d: 0.25,
                label: i18n.global.t('racing.label_distance_025_short'),
            },
            {
                d: 0.3,
                label: i18n.global.t('racing.label_distance_03_short'),
            },
        ]

        // Initialize variables to store the closest target and the smallest difference
        let closestTarget = targets[0].d
        let smallestDifference = Math.abs(distance - closestTarget)

        // Loop through each target to find the closest one
        for (let i = 1; i < targets.length; i++) {
            const currentDifference = Math.abs(distance - targets[i].d)
            if (currentDifference < smallestDifference) {
                closestTarget = targets[i].d
                smallestDifference = currentDifference
            }
        }

        return targets.find((target) => target.d === closestTarget)?.label || ''
    }
}

/**
 * Get array of objects with titles and values for horse name tooltip on race card
 */
export function getHorseStatsForTooltip(runner: OBR.Race.Runner) {
    const result = []
    if (runner.sire) {
        let value = runner.sire
        if (runner.dam) {
            value += ` - ${runner.dam}`
            if (runner.dam_sire) {
                value += ` (${runner.dam_sire})`
            }
        }
        result.push({ key: 'racing.label_breeding', value })
    }

    if (runner.owner) {
        result.push({ key: 'racing.label_owner', value: runner.owner })
    }

    if (runner.breeder) {
        result.push({
            key: 'racing.label_breeder',
            value: runner.breeder,
        })
    }

    if (runner.colour) {
        result.push({
            key: 'racing.label_horse_colour',
            value: i18n.global.t(`racing.label_colour_${runner.colour}`),
        })
    }

    if (runner.pp?.purse && runner.pp?.purse_currency) {
        result.push({
            key: 'racing.label_winnings_another',
            value: formatToLocaleNumberWithCurrency(
                runner.pp.purse,
                runner.pp.purse_currency
            ),
        })
    }
    return result
}

/**
 * Get array of objects with titles and values for trainer or jockey tooltip on race card
 */
export function getPersonStatsForTooltip(
    stats: OBR.Race.RunnerStats | null,
    currency: OBR.User.Currency
) {
    const result = []

    if (stats && stats.num_races) {
        result.push({
            key: 'racing.label_starts',
            value: stats.num_races,
        })

        result.push({
            key: 'racing.label_wins',
            value: stats.num_win
                ? `${stats.num_win} (${Math.round(
                      (stats.num_win / stats.num_races) * 100
                  )}%)`
                : '-',
        })

        result.push({
            key: 'racing.label_places',
            value: stats.num_place
                ? `${stats.num_place} (${Math.round(
                      (stats.num_place / stats.num_races) * 100
                  )}%)`
                : '-',
        })
        result.push({
            key: 'racing.label_winnings',
            value: formatToLocaleNumberWithCurrency(stats.earnings, currency),
        })
    }

    return result
}
